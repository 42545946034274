export interface LanguageDetectorConfig {
  defaultLanguage: string;
  supportedLanguages: string[];
}

export class LanguageDetector {
  private config: LanguageDetectorConfig;
  private readonly STORAGE_KEY = 'user-language-preference';

  constructor(config: LanguageDetectorConfig) {
    this.config = config;
  }

  detectLanguage(): string {
    // First, check if language is in URL path
    const pathLanguage = this.getLanguageFromPath();
    if (pathLanguage) {
      this.saveLanguagePreference(pathLanguage);
      return pathLanguage;
    }

    // Second, check localStorage for saved preference
    const savedLanguage = this.getSavedLanguagePreference();
    if (savedLanguage) {
      return savedLanguage;
    }

    // Third, check browser settings
    const browserLanguage = this.getBrowserLanguage();
    if (browserLanguage) {
      return browserLanguage;
    }

    // Default fallback
    return this.config.defaultLanguage;
  }

  getLanguageFromPath(): string | null {
    const pathSegments = window.location.pathname.split('/').filter(Boolean);
    if (pathSegments.length > 0) {
      const possibleLang = pathSegments[0];
      if (this.config.supportedLanguages.includes(possibleLang)) {
        return possibleLang;
      }
    }
    return null;
  }

  getSavedLanguagePreference(): string | null {
    const saved = localStorage.getItem(this.STORAGE_KEY);
    if (saved && this.config.supportedLanguages.includes(saved)) {
      return saved;
    }
    return null;
  }

  getBrowserLanguage(): string | null {
    const browserLang = navigator.language.split('-')[0];
    if (this.config.supportedLanguages.includes(browserLang)) {
      return browserLang;
    }
    return null;
  }

  saveLanguagePreference(language: string): void {
    if (this.config.supportedLanguages.includes(language)) {
      localStorage.setItem(this.STORAGE_KEY, language);
    }
  }

  // Improved method to generate proper language-specific URLs
  generateLocalizedPath(path: string, language: string): string {
    if (!path) return `/${language}`;
    
    // Remove leading slash if present
    const normalizedPath = path.startsWith('/') ? path.substring(1) : path;
    
    // Split path into segments
    const pathSegments = normalizedPath.split('/').filter(Boolean);
    
    // If path is empty, just return the language
    if (pathSegments.length === 0) {
      return `/${language}`;
    }
    
    // Check if the first segment is already a language code
    if (this.config.supportedLanguages.includes(pathSegments[0])) {
      // Replace the language code
      pathSegments[0] = language;
    } else {
      // Add the language code at the beginning
      pathSegments.unshift(language);
    }
    
    // Reconstruct the path with leading slash
    return `/${pathSegments.join('/')}`;
  }
  
  // New utility to check if a path should be localized
  shouldLocalizeRoute(path: string): boolean {
    // Only auth routes don't need localization
    const nonLocalizedPaths = [
      '/login', 
      '/signup',
      '/reset-password', 
      '/payment-success', 
      '/post-signup'
    ];
    
    return !nonLocalizedPaths.some(prefix => path.startsWith(prefix));
  }
}