import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../store';
import Header from './Header';
import Footer from './Footer';
import CookieBanner from './CookieBanner';
import flower from '../assets/images/flower_1.png';

interface BaseLayoutProps {
  children: React.ReactNode;
  title?: string;
  description?: string;
  keywords?: string;
  imageUrl?: string;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ 
  children,
  title,
  description,
  keywords,
  imageUrl = '/logo512.png'
}) => {
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const location = useLocation();
  
  // Get default values based on language
  const getDefaultTitle = (): string => {
    return currentLanguage === 'de' 
      ? 'HEARTPORT PUBLIC'
      : 'HEARTPORT PUBLIC';
  };
  
  const getDefaultDescription = (): string => {
    return currentLanguage === 'de'
      ? 'Entdecken Sie wissenschaftlich fundierte Gesundheitseinblicke mit HEARTPORT, wo KI wissenschaftliche Forschung in leicht verständliche Hearticles für bessere Gesundheit, Leistung und Langlebigkeit umwandelt.'
      : 'Discover science-backed health insights with HEARTPORT, where AI transforms scientific research into easy-to-understand Hearticles for better health, performance, and longevity.';
  };
  
  const getDefaultKeywords = (): string => {
    return currentLanguage === 'de'
      ? 'Präventivmedizin, wissenschaftliche Gesundheitsartikel, KI-Gesundheitsplattform, Gesundheitsoptimierung, Hearticles, Herzgesundheit, mentales Wohlbefinden, Ernährungsmedizin, Langlebigkeit'
      : 'preventive health, scientific health articles, AI health platform, health optimization, Hearticles, heart health, mental wellness, nutrition, longevity, health science';
  };
  
  // Get language information for meta tags
  const ogLocale = currentLanguage === 'de' ? 'de_DE' : 'en_US';
  const ogLocaleAlternate = currentLanguage === 'de' ? 'en_US' : 'de_DE';
  
  // Build canonical and alternate URLs
  const pathWithoutLang = location.pathname.replace(/^\/[a-z]{2}/, '');
  const canonicalUrl = `${window.location.origin}/${currentLanguage}${pathWithoutLang}${location.search}`;
  const alternateUrlEn = `${window.location.origin}/en${pathWithoutLang}${location.search}`;
  const alternateUrlDe = `${window.location.origin}/de${pathWithoutLang}${location.search}`;
  
  // Ensure image URL is absolute
  const absoluteImageUrl = imageUrl.startsWith('http') 
    ? imageUrl 
    : `${window.location.origin}${imageUrl}`;
  
  return (
    <div className="min-h-screen text-white bg-[#CAD4DD]">
      <Helmet>
        {/* Basic Meta Tags */}
        <html lang={currentLanguage} />
        <title>{title || getDefaultTitle()}</title>
        <meta name="description" content={description || getDefaultDescription()} />
        <meta name="keywords" content={keywords || getDefaultKeywords()} />
        
        {/* Canonical URL */}
        <link rel="canonical" href={canonicalUrl} />
        
        {/* Language Alternatives */}
        <link rel="alternate" hrefLang="en" href={alternateUrlEn} />
        <link rel="alternate" hrefLang="de" href={alternateUrlDe} />
        <link rel="alternate" hrefLang="x-default" href={alternateUrlEn} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title || getDefaultTitle()} />
        <meta property="og:description" content={description || getDefaultDescription()} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta property="og:locale" content={ogLocale} />
        <meta property="og:locale:alternate" content={ogLocaleAlternate} />
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:title" content={title || getDefaultTitle()} />
        <meta name="twitter:description" content={description || getDefaultDescription()} />
        <meta name="twitter:image" content={absoluteImageUrl} />
      </Helmet>
      
      <Header />
      <main>
        {children}
      </main>
      <CookieBanner flowerImagePath={flower} />
      <Footer />
    </div>
  );
};

export default BaseLayout;