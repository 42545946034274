import { useState, useEffect } from "react";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import axios from "../axios";
import { ChevronDown, ChevronUp, Search, Trash2, UserCog } from "lucide-react";

interface Role {
  id: number;
  name: string;
}

interface SubscriptionLevel {
  id: number;
  name: string;
}

interface User {
  id: number;
  username: string;
  email: string;
  subscriptionStatus: 'ACTIVE' | 'PAST_DUE' | 'CANCELED' | 'UNPAID';
  subscriptionLevel?: SubscriptionLevel;
  roles: Role[];
}

// Available roles
const AVAILABLE_ROLES = ['ROLE_ADMIN', 'ROLE_REVIEWER'];

const UserManagement = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchUsername, setSearchUsername] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchStatus, setSearchStatus] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [showRolesDropdown, setShowRolesDropdown] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);

  const hasAdminPermission = useSelector((state: RootState) => {
    return state.auth.user?.roles?.includes('PERMISSION_ADMIN') ?? false;
  });

  useEffect(() => {
    if (hasAdminPermission) {
      fetchUsers();
    }
  }, [hasAdminPermission]);

  const fetchUsers = async (searchParams = {}) => {
    setIsLoading(true);
    try {
      const response = await axios.get<User[]>('/api/admin/users/search', { params: searchParams });
      setUsers(response.data || []); // Ensure we always have an array
      setError(null);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      setError('Failed to load users. Please try again later.');
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    const params: Record<string, string> = {};
    if (searchUsername) params.username = searchUsername;
    if (searchEmail) params.email = searchEmail;
    if (searchStatus) params.status = searchStatus;
    fetchUsers(params);
  };

  const handleUserSelect = (user: User) => {
    setSelectedUser(user);
    setSelectedRoles(user.roles?.map(role => role.name) || []);
    setShowRolesDropdown(false);
    setUpdateSuccess(null);
  };

  const handleSaveChanges = async () => {
    if (!selectedUser) return;

    try {
      await axios.post('/api/admin/users', {
        username: selectedUser.username,
        email: selectedUser.email,
        roleNames: selectedRoles
      });

      setUpdateSuccess('User roles updated successfully');
      fetchUsers(); // Refresh the user list
      setTimeout(() => {
        setSelectedUser(null);
        setShowRolesDropdown(false);
        setUpdateSuccess(null);
      }, 2000);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Failed to update user roles');
    }
  };

  const handleDeleteUser = async (userId: number) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;
    
    try {
      await axios.delete(`/api/admin/users/${userId}`);
      fetchUsers();
      setError(null);
    } catch (error) {
      console.error('Failed to delete user:', error);
      setError('Failed to delete user. Please try again later.');
    }
  };

  if (!hasAdminPermission) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="mt-8">
        <h2 className="text-xl font-bold text-gray-900 mb-4">User Management</h2>
        <div className="bg-white rounded-lg shadow p-6">
          <div className="text-center py-8">Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl font-bold text-gray-900 mb-4">User Management</h2>
      <div className="bg-white rounded-lg shadow p-6 space-y-6">
        {/* Search Controls */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Username</label>
            <div className="relative">
              <input
                type="text"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg text-black"
                value={searchUsername}
                onChange={(e) => setSearchUsername(e.target.value)}
                placeholder="Search by username"
              />
              <Search className="absolute right-3 top-2.5 text-gray-400" size={20} />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
            <input
              type="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg text-black"
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
              placeholder="Search by email"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
            <select
              className="w-full px-4 py-2 border border-gray-300 rounded-lg text-black"
              value={searchStatus}
              onChange={(e) => setSearchStatus(e.target.value)}
            >
              <option value="">All Status</option>
              <option value="ACTIVE">Active</option>
              <option value="PAST_DUE">Past Due</option>
              <option value="CANCELED">Canceled</option>
              <option value="UNPAID">Unpaid</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSearch}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Search Users
          </button>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
            {error}
          </div>
        )}

        {/* Users Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left text-black text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Roles</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 text-black">
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="px-4 py-3">{user.username}</td>
                  <td className="px-4 py-3">{user.email}</td>
                  <td className="px-4 py-3">{user.roles?.map(role => role.name).join(', ') || 'No roles'}</td>
                  <td className="px-4 py-3">
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleUserSelect(user)}
                        className="p-1 hover:bg-gray-100 rounded"
                        title="Edit User"
                      >
                        <UserCog size={20} className="text-blue-500" />
                      </button>
                      <button
                        onClick={() => handleDeleteUser(user.id)}
                        className="p-1 hover:bg-gray-100 rounded"
                        title="Delete User"
                      >
                        <Trash2 size={20} className="text-red-500" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Edit User Modal */}
        {selectedUser && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
              <h3 className="text-lg font-bold mb-4">Edit User: {selectedUser.username}</h3>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Roles</label>
                  <div className="relative">
                    <button
                      onClick={() => setShowRolesDropdown(!showRolesDropdown)}
                      className="w-full flex justify-between items-center px-4 py-2 bg-white border border-gray-300 rounded-lg text-black"
                    >
                      <span>{selectedRoles.join(', ') || 'Select Roles'}</span>
                      {showRolesDropdown ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </button>
                    {showRolesDropdown && (
                      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg text-black">
                        <div className="p-2">
                          {AVAILABLE_ROLES.map((roleName) => (
                            <label key={roleName} className="flex items-center p-2 hover:bg-gray-50">
                              <input
                                type="checkbox"
                                checked={selectedRoles.includes(roleName)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedRoles([...selectedRoles, roleName]);
                                  } else {
                                    setSelectedRoles(selectedRoles.filter(r => r !== roleName));
                                  }
                                }}
                                className="mr-2"
                              />
                              {roleName}
                            </label>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {updateSuccess && (
                <div className="mt-4 p-3 bg-green-100 border border-green-400 text-green-700 rounded">
                  {updateSuccess}
                </div>
              )}

              <div className="flex justify-end space-x-2 mt-6">
                <button
                  onClick={() => {
                    setSelectedUser(null);
                    setShowRolesDropdown(false);
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 text-black"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveChanges}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagement;