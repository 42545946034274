import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getCurrentUser, restoreToken } from './store/authSlice';
import { LanguageDetector } from './utils/languageDetection';
import { setLanguage } from './store/languageSlice';
import HeartPort from './components/HeartPort';
import FullHearticlePage from './components/FullHearticlePage';
import Login from './components/Login';
import Signup from './components/Signup';
import ReviewPage from './components/ReviewPage';
import Profile from './components/Profile';
import { AppDispatch, RootState } from './store';
import ResetPassword from './components/PasswordResetComponent';
import Crew from './components/Crew';
import { useTranslation } from 'react-i18next';
import { fetchDailyReadCount } from './store/readCountSlice';
import PaymentSuccess from './components/PaymentSuccess';
import PostSignup from './components/PostSignup';
import BaseLayout from './components/BaseLayout';

// Language configuration
const SUPPORTED_LANGUAGES = ['de', 'en'];
const DEFAULT_LANGUAGE = 'de';

const AppContent: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [authLoading, setAuthLoading] = useState(true);
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    // Initialize language detection
    const languageDetector = new LanguageDetector({
      defaultLanguage: DEFAULT_LANGUAGE,
      supportedLanguages: SUPPORTED_LANGUAGES
    });

    const detectedLanguage = languageDetector.detectLanguage();
    dispatch(setLanguage(detectedLanguage));
    i18n.changeLanguage(detectedLanguage);
    languageDetector.saveLanguagePreference(detectedLanguage);
    
    // Set HTML lang attribute
    document.documentElement.lang = detectedLanguage;
    
    // Initialize auth
    dispatch(restoreToken());
    setAuthLoading(false);
  }, [dispatch, i18n]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
      dispatch(fetchDailyReadCount());
    }
  }, [dispatch, isAuthenticated]);

  // This effect ensures URL path matches the current language
  useEffect(() => {
    // Don't redirect during auth loading
    if (authLoading) return;

    // Define auth routes that should never have language prefixes
    const authRoutes = ['/login', '/signup', '/reset-password', '/payment-success', '/post-signup'];
    
    // Check if we're on an auth route
    if (authRoutes.some(route => location.pathname === route || location.pathname.startsWith(route + '/'))) {
      // We're on an auth route, don't add language prefix
      return;
    }

    // Get path segments
    const pathSegments = location.pathname.split('/').filter(Boolean);
    
    // Check if current path needs language prefix
    if (pathSegments.length === 0 || !SUPPORTED_LANGUAGES.includes(pathSegments[0])) {
      // Path doesn't have language prefix, don't redirect automatically as this will be handled by route definitions
      return;
    }
    
    // If path has language prefix but it's different from current language, update it
    if (pathSegments[0] !== currentLanguage) {
      // This needs to be handled by specific routes to avoid infinite redirects
      return;
    }
  }, [location.pathname, currentLanguage, authLoading]);

  // Create a route guard component for authenticated routes
  const AuthGuard = ({ children }: { children: React.ReactNode }) => {
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
    return <>{children}</>;
  };

  // Use BaseLayout for main public routes
  const WrappedRoutes = () => (
    <BaseLayout>
      <Routes>
        {/* Root route redirects to current language */}
        <Route path="/" element={<Navigate to={`/${currentLanguage}`} replace />} />
        
        {/* Routes with language prefixes */}
        {SUPPORTED_LANGUAGES.map(lang => (
          <React.Fragment key={lang}>
            <Route path={`/${lang}`} element={<HeartPort />} />
            <Route path={`/${lang}/playlist/:playlistId`} element={<HeartPort />} />
            <Route path={`/${lang}/playlist/unpublished/:playlistId`} element={<HeartPort />} />
            <Route path={`/${lang}/hearticle/:slug/:id`} element={<FullHearticlePage />} />
            <Route path={`/${lang}/explore`} element={<Navigate to={`/${lang}?mode=explore`} replace />} />
            <Route path={`/${lang}/crew`} element={<Crew />} />
            <Route path={`/${lang}/profile`} element={
              <AuthGuard>
                <Profile />
              </AuthGuard>
            } />
            <Route path={`/${lang}/review/translation/:id`} element={<ReviewPage />} />
          </React.Fragment>
        ))}
        
        {/* Legacy/non-prefixed routes that redirect to language-prefixed routes */}
        <Route path="/hearticle/:slug/:id" element={<Navigate to={`/${currentLanguage}/hearticle/:slug/:id`} replace />} />
        <Route path="/playlist/:playlistId" element={<HeartPort />}  />
        <Route path="/playlist/unpublished/:playlistId" element={<HeartPort />}  />
        <Route path="/explore" element={<Navigate to={`/${currentLanguage}/explore`} replace />} />
        <Route path="/crew" element={<Navigate to={`/${currentLanguage}/crew`} replace />} />
        <Route path="/profile" element={<Navigate to={`/${currentLanguage}/profile`} replace />} />
        <Route path="/review/translation/:id" element={<Navigate to={`/${currentLanguage}/review/translation/:id`} replace />} />
        
        {/* Special case: direct /hearticle/:id support for backward compatibility */}
        <Route path="/hearticle/:id" element={<FullHearticlePage />} />
      </Routes>
    </BaseLayout>
  );

  // Categorize routes by authentication and layout needs
  const authRoutes = ['/login', '/signup', '/reset-password', '/payment-success', '/post-signup'];
  const currentPath = location.pathname;
  const needsBaseLayout = !authRoutes.some(route => currentPath.startsWith(route));
  
  return (
    <Routes>
      {/* Auth routes without BaseLayout - NEVER add language prefix to these */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/post-signup" element={<PostSignup />} />
      
      {/* Catch any attempts to access localized auth routes and redirect */}
      {SUPPORTED_LANGUAGES.map(lang => (
        <React.Fragment key={`auth-redirect-${lang}`}>
          <Route path={`/${lang}/login`} element={<Navigate to="/login" replace />} />
          <Route path={`/${lang}/signup`} element={<Navigate to="/signup" replace />} />
          <Route path={`/${lang}/reset-password`} element={<Navigate to="/reset-password" replace />} />
          <Route path={`/${lang}/payment-success`} element={<Navigate to="/payment-success" replace />} />
          <Route path={`/${lang}/post-signup`} element={<Navigate to="/post-signup" replace />} />
        </React.Fragment>
      ))}
      
      {/* Review route with BaseLayout */}
      <Route path="/review/translation/:id" element={
        <BaseLayout>
          <ReviewPage />
        </BaseLayout>
      } />
      
      {/* All other routes use the BaseLayout */}
      <Route path="/*" element={<WrappedRoutes />} />
    </Routes>
  );
};

export default AppContent;