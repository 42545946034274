import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from '../axios';
import { Plus, Edit2, Trash2, Image, X } from 'lucide-react';
import { RootState } from '../store';
import { Hearticle } from '../types';
import PlaylistCard from './PlaylistCard';

interface Playlist {
  id: number;
  name: string;
  nameEn?: string;
  description: string;
  creationDate: string;
  lastModifiedDate: string;
  status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
  hearticles: Hearticle[];
  creator?: {
    id: number;
    username: string;
  };
  editorIds?: number[];
}

interface PlaylistImageState {
  file: File | null;
  error: string | null;
}

const PlaylistManagement: React.FC = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Map of playlist ID to image state (selected file, etc.)
  const [playlistImages, setPlaylistImages] = useState<Record<number, PlaylistImageState>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [guidedForm, setGuidedForm] = useState({
    playlistNameDe: '',
    playlistNameEn: '',
    expertName: '',
    subtopicsRaw: '',
    color: '#3B82F6',
  });

  const user = useSelector((state: RootState) => state.auth.user);
  const canCreateGuided = user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES');

  useEffect(() => {
    fetchPlaylists();
  }, []);

  const fetchPlaylists = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get<Playlist[]>('/api/playlist');
      setPlaylists(response.data || []);
      setError(null);
    } catch (err) {
      setError('Failed to fetch playlists');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageSelect =
    (playlistId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      if (file.size > 5 * 1024 * 1024) {
        setPlaylistImages((prev) => ({
          ...prev,
          [playlistId]: { file: null, error: 'Image size should be less than 5MB' },
        }));
        return;
      }

      if (file.type !== 'image/png') {
        setPlaylistImages((prev) => ({
          ...prev,
          [playlistId]: { file: null, error: 'Only PNG files are allowed' },
        }));
        return;
      }

      setPlaylistImages((prev) => ({
        ...prev,
        [playlistId]: { file, error: null },
      }));
    };

  const handleImageUpload = async (playlistId: number, playlistName: string) => {
    const imageState = playlistImages[playlistId];
    if (!imageState?.file) return;

    const sanitizedFilename = playlistName.toLowerCase().replace(/ /g, '_');
    const formData = new FormData();
    formData.append('image', imageState.file);
    formData.append('filename', sanitizedFilename);

    try {
      await axios.post(`/api/topics/${playlistId}/image`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      // Clear the image state for this playlist
      setPlaylistImages((prev) => {
        const newState = { ...prev };
        delete newState[playlistId];
        return newState;
      });
    } catch (err) {
      setPlaylistImages((prev) => ({
        ...prev,
        [playlistId]: { ...prev[playlistId], error: 'Failed to upload image' },
      }));
    }
  };

  const clearImageSelection = (playlistId: number) => {
    setPlaylistImages((prev) => {
      const newState = { ...prev };
      delete newState[playlistId];
      return newState;
    });
  };

  const handleCreateGuidedPlaylist = async () => {
    setIsSubmitting(true);
    try {
      const lines = guidedForm.subtopicsRaw
        .split(/[\n,]+/)
        .map((s) => s.trim())
        .filter(Boolean);
  
      await axios.post('/api/playlist/guided', {
        playlistNameDe: guidedForm.playlistNameDe,
        playlistNameEn: guidedForm.playlistNameEn,
        expertName: guidedForm.expertName,
        subtopics: lines,
        color: guidedForm.color, // Include color in the request
      });
  
      setGuidedForm({
        playlistNameDe: '',
        playlistNameEn: '',
        expertName: '',
        subtopicsRaw: '',
        color: '#3B82F6',
      });
      setShowCreateModal(false);
      fetchPlaylists();
    } catch (err) {
      setError('Failed to create guided playlist');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateTopicColor = async (playlistId: number, color: string) => {
    try {
      await axios.put(`/api/playlist/${playlistId}/topic/color`, { color });
      fetchPlaylists();
    } catch (err) {
      setError('Failed to update topic color');
    }
  };
  

  const handleToggleStatus = async (playlist: Playlist) => {
    try {
      await axios.put(`/api/playlist/${playlist.id}/status`);
      fetchPlaylists();
    } catch (err) {
      setError('Failed to update playlist status');
    }
  };

  const handleDeletePlaylist = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this playlist?')) return;
    try {
      await axios.delete(`/api/playlist/${id}`);
      fetchPlaylists();
    } catch (err) {
      setError('Failed to delete playlist');
    }
  };

  if (isLoading) return <div className="p-6">Loading...</div>;
  if (error) return <div className="p-6 text-red-500">{error}</div>;

  return (
    <div className="p-6 min-h-screen bg-gray-50">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Playlists</h2>
        {canCreateGuided && (
          <button
            onClick={() => setShowCreateModal(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg flex items-center gap-2 hover:bg-blue-700 transition-colors"
          >
            <Plus size={20} />
            Create Guided Playlist
          </button>
        )}
      </div>

      {/* Grid of playlists */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
        {playlists.map((playlist) => (
          <PlaylistCard
            key={playlist.id}
            playlist={playlist}
            handleUpdateTopicColor={handleUpdateTopicColor}
            handleImageSelect={handleImageSelect}
            handleToggleStatus={handleToggleStatus}
            playlistImages={playlistImages}
            clearImageSelection={clearImageSelection}
            handleImageUpload={handleImageUpload}
          />
        ))}
      </div>

      {/* Modal */}
      {showCreateModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 text-black">
          <div className="bg-white rounded-xl p-6 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <h3 className="text-xl font-bold text-gray-900 mb-6">Create Guided Playlist</h3>

            <div className="space-y-4 mb-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  German Name
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={guidedForm.playlistNameDe}
                  onChange={(e) =>
                    setGuidedForm((prev) => ({
                      ...prev,
                      playlistNameDe: e.target.value,
                    }))
                  }
                  disabled={isSubmitting}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  English Name
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={guidedForm.playlistNameEn}
                  onChange={(e) =>
                    setGuidedForm((prev) => ({
                      ...prev,
                      playlistNameEn: e.target.value,
                    }))
                  }
                  disabled={isSubmitting}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Expert Name (optional)
                </label>
                <input
                  type="text"
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  value={guidedForm.expertName}
                  onChange={(e) =>
                    setGuidedForm((prev) => ({
                      ...prev,
                      expertName: e.target.value,
                    }))
                  }
                  disabled={isSubmitting}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Color
                </label>
                <div className="flex items-center gap-3">
                  <input
                    type="color"
                    className="h-10 w-14 p-1 border border-gray-300 rounded-lg cursor-pointer"
                    value={guidedForm.color}
                    onChange={(e) =>
                      setGuidedForm((prev) => ({
                        ...prev,
                        color: e.target.value,
                      }))
                    }
                    disabled={isSubmitting}
                  />
                  <span className="text-sm text-gray-600 font-mono">
                    {guidedForm.color.toUpperCase()}
                  </span>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Subtopics (comma or newline-separated)
                </label>
                <textarea
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  rows={4}
                  value={guidedForm.subtopicsRaw}
                  onChange={(e) =>
                    setGuidedForm((prev) => ({
                      ...prev,
                      subtopicsRaw: e.target.value,
                    }))
                  }
                  placeholder="e.g. Subtopic A, Subtopic B&#10;or line by line..."
                  disabled={isSubmitting}
                />
              </div>
            </div>

            <div className="flex justify-end items-center gap-3">
              <button
                onClick={() => !isSubmitting && setShowCreateModal(false)}
                className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors text-gray-700"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                onClick={handleCreateGuidedPlaylist}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Creating…' : 'Create'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaylistManagement;
