import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { Hearticle, HearticleTranslation, InteractionDTO, InteractionHearticleClass, InteractionType, SharePlatform } from '../types';
import { RootState } from '../store';
import PlayButton from './PlayButton';
import insta from '../assets/images/insta_btn.png';
import linkedin from '../assets/images/linkedin_btn.png';
import x from '../assets/images/x_btn.png';
import mail from '../assets/images/mail_btn.png';
import phone from '../assets/images/phone_btn.png';
import ReviewBadge from './ReviewBadge';
import { getFeaturedTopicForDate } from '../utils/featuredTopicUtils';
import ArticleCard from './ArticleCard';
import { Share } from 'lucide-react';
import { useCanTrackInteractions } from '../hooks/cookieConsent';
import PaywallModal from './PaywallModal';
import SideHearticleCard from './SideHearticleCard';

interface Playlist {
  id: number;
  name: string;
  nameEn?: string;
  description: string;
  hearticles: Hearticle[];
  status: 'ACTIVE' | 'ARCHIVED';
}

interface ShareButtonsProps {
  hearticleId: string;
  onShare: (platform: string) => void;
}

interface Reviewer {
  id: number;
  username: string;
  email: string;
}

interface Review {
  id: number;
  reviewer: Reviewer;
  status: string;
}

const ShareButtons: React.FC<ShareButtonsProps> = ({ hearticleId, onShare }) => {
  return (
    <div className="flex space-x-1 md:space-x-2">
      {/* Mobile Share Button */}
      <div className="block md:hidden">
        <button 
          onClick={() => onShare('native')}
          className="w-8 h-8 flex items-center justify-center bg-white rounded-full shadow-md hover:opacity-80 transition-opacity"
          aria-label="Share"
        >
          <Share className="w-5 h-5 text-gray-600" />
        </button>
      </div>

      {/* Desktop Social Media Buttons */}
      <div className="hidden md:flex md:space-x-2">
        <button onClick={() => onShare('instagram')} className="w-12 h-12 overflow-hidden hover:opacity-80 transition-opacity">
          <img src={insta} alt="Share on Instagram" className="w-full h-full object-cover" />
        </button>
        <button onClick={() => onShare('linkedin')} className="w-12 h-12 overflow-hidden hover:opacity-80 transition-opacity">
          <img src={linkedin} alt="Share on LinkedIn" className="w-full h-full object-cover" />
        </button>
        <button onClick={() => onShare('twitter')} className="w-12 h-12 overflow-hidden hover:opacity-80 transition-opacity">
          <img src={x} alt="Share on Twitter" className="w-full h-full object-cover" />
        </button>
        <button onClick={() => onShare('email')} className="w-12 h-12 overflow-hidden hover:opacity-80 transition-opacity">
          <img src={mail} alt="Share via Email" className="w-full h-full object-cover" />
        </button>
      </div>
    </div>
  );
};

const EnhancedShareButtons: React.FC<{ hearticleId: string }> = ({ hearticleId }) => {
  const canTrackInteractions = useSelector(useCanTrackInteractions());
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [headline, setHeadline] = useState<string>("");

  // Fetch the headline for the current hearticle to generate proper slug
  useEffect(() => {
    const fetchHearticleHeadline = async () => {
      try {
        const response = await axios.get(`/api/public/hearticle/${hearticleId}`);
        const hearticle = response.data;
        const translation = hearticle.translations.find(
          (t: any) => t.languageCode === currentLanguage
        ) || hearticle.translations[0];
        
        setHeadline(translation.headline);
      } catch (error) {
        console.error('Error fetching hearticle headline:', error);
      }
    };

    if (hearticleId) {
      fetchHearticleHeadline();
    }
  }, [hearticleId, currentLanguage]);

  // Generate slug from headline
  const generateSlug = (text: string): string => {
    return text
      .toLowerCase()
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
      .trim();
  };

  const handleShare = async (platform: string) => {
    if (canTrackInteractions) {
      try {
        const interaction: InteractionDTO = {
          hearticleId: Number(hearticleId),
          interactionType: InteractionType.SHARE,
          sharePlatform: platform as SharePlatform,
          interactionHearticleClass: InteractionHearticleClass.NORMAL
          
        };
        await axios.post('/api/interactions', interaction);
      } catch (error) {
        console.error('Failed to record share interaction:', error);
      }
    }
    
    // Get canonical URL for sharing, using the headline if available
    const url = headline 
      ? `${window.location.origin}/${currentLanguage}/hearticle/${generateSlug(headline)}/${hearticleId}`
      : `${window.location.origin}/hearticle/${hearticleId}`;
    
    if (platform === 'native' && navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this interesting Hearticle!',
          url: url
        });
        return;
      } catch (error) {
        console.error('Failed to use native share:', error);
        // Fall through to platform-specific sharing if native share fails
      }
    }
    
    switch (platform) {
      case 'instagram':
        try {
          await navigator.clipboard.writeText(url);
          alert('Link copied! To share on Instagram:\n\n1. Open Instagram\n2. Create a new Story\n3. Paste the link using the "Link" sticker');
        } catch (error) {
          console.error('Failed to copy link:', error);
          alert('Failed to copy link. Please try again.');
        }
        break;
        
      case 'linkedin':
        window.open(`https://www.linkedin.com/feed/share?url=${encodeURIComponent(url)}&title=${encodeURIComponent('Check out this interesting Hearticle!')}`, '_blank');
        break;
        
      case 'twitter':
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent('Check out this interesting Hearticle!')}`, '_blank');
        break;
        
      case 'email':
        window.open(`mailto:?subject=${encodeURIComponent('Check out this interesting Hearticle!')}&body=${encodeURIComponent(url)}`, '_blank');
        break;
    }
  };

  return <ShareButtons hearticleId={hearticleId} onShare={handleShare} />;
};


const HearticleDisplay = () => {
  const { t, i18n } = useTranslation();
  const { playlistId } = useParams();
  const [playlist, setPlaylist] = useState<Playlist | null>(null);
  const [dailyHearticles, setDailyHearticles] = useState<Hearticle[]>([]);
  const [loading, setLoading] = useState(true);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [translationReviews, setTranslationReviews] = useState<{ [translationId: number]: Review[] }>({});

  const [featuredArticle, setFeaturedArticle] = useState<Hearticle | null>(null);
  const [sideHearticles, setSideHearticles] = useState<Hearticle[]>([]);
  const [gridHearticles, setGridHearticles] = useState<Hearticle[]>([]);
  const [videoError, setVideoError] = useState(false);
  const [topicName, setTopicName] = useState('');
  const [playlistName, setPlaylistName] = useState('');

  // Generate slug from headline
  const generateSlug = (text: string): string => {
    return text
      .toLowerCase()
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
      .trim();
  };

  useEffect(() => {
    const currentLang = i18n.language;
    const name = currentLang === 'en' && featuredArticle?.topic.nameEn
      ? featuredArticle?.topic.nameEn
      : featuredArticle?.topic.name;
    if (name) setTopicName(name);
  }, [i18n.language, featuredArticle?.topic.name, featuredArticle?.topic.nameEn]);
  
  useEffect(() => {
    if (playlist) {
      const currentLang = i18n.language;
      const name = currentLang === 'en' && playlist.nameEn
        ? playlist.nameEn
        : playlist.name;
      setPlaylistName(name);
    }
  }, [i18n.language, playlist]);

  useEffect(() => {
    if (videoError) {
      console.log('Featured video failed to load');
    }
  }, [videoError]);

  useEffect(() => {
    const fetchContent = async () => {
      console.log("fetching content")
      setLoading(true);
      try {
        if (playlistId) {
          console.log("playlist: ", playlistId)

          // Determine the endpoint based on whether we're viewing published or unpublished content
          const endpoint = window.location.pathname.includes('/unpublished') 
            ? `/api/playlist/public/unpublished/${playlistId}`
            : `/api/playlist/public/${playlistId}`;
            
          const response = await axios.get<Playlist>(endpoint);
          console.log("playlist response: ", response.data)

          setPlaylist(response.data);
        } else {
          console.log("no playlist")

          // Daily hearticles fetch remains the same
          const targetDate = new Date().toISOString().split('T')[0];
          const response = await axios.get<Hearticle[]>('/api/public/hearticles', {
            params: { publicationDate: targetDate },
          });
          
          // Get featured topic for today
          const featuredTopic = await getFeaturedTopicForDate(new Date());
          
          // Find featured article and organize remaining articles
          const featured = featuredTopic 
            ? response.data.find(h => h.topic.id === featuredTopic.id) 
            : response.data[0];
            
          if (featured) {
            const remaining = response.data.filter(h => h.id !== featured.id);
            setFeaturedArticle(featured);
            setSideHearticles(remaining.slice(0, 2));
            setGridHearticles(remaining.slice(2));
          }
          
          setDailyHearticles(response.data);
        }
      } catch (error) {
        console.error('Error fetching content:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, [playlistId]);

  useEffect(() => {
    const fetchReviewsForTranslations = async (translations: HearticleTranslation[]): Promise<void> => {
      const translationReviewPromises = translations.map(async (translation) => {
        try {
          const response = await axios.get<Review[]>(`/api/review/translation/${translation.id}`);
          return { translationId: translation.id, reviews: response.data };
        } catch (error) {
          console.error(`Error fetching reviews for translation ${translation.id}:`, error);
          return { translationId: translation.id, reviews: [] };
        }
      });
    
      const results = await Promise.all(translationReviewPromises);
      const reviewsMap = results.reduce((acc, curr) => {
        acc[curr.translationId] = curr.reviews;
        return acc;
      }, {} as { [translationId: number]: Review[] });
      
      setTranslationReviews(reviewsMap);
    };

    const allTranslations: HearticleTranslation[] = [];
    if (playlist) {
      playlist.hearticles.forEach((hearticle) => {
        allTranslations.push(getTranslation(hearticle));
      });
    } else if (dailyHearticles.length > 0) {
      dailyHearticles.forEach((hearticle) => {
        allTranslations.push(getTranslation(hearticle));
      });
    }

    if (allTranslations.length > 0) {
      fetchReviewsForTranslations(allTranslations);
    }
  }, [playlist, dailyHearticles, currentLanguage]);

  const getTranslation = (hearticle: Hearticle): HearticleTranslation => {
    return (
      hearticle.translations.find((t) => t.languageCode === currentLanguage) ||
      hearticle.translations[0]
    );
  };

  const getImageUrl = (topicName: string) => {
    let imageName = topicName.toLowerCase().replace(/ /g, '_') + '.png';
    if (imageName === 'recover:_immunity_&_supplements.png')
      imageName = 'recover_immunity_&_supplements.png';
    imageName = 'big/' + imageName;
    return `${process.env.REACT_APP_API_URL}/uploads/topic_images/${imageName}`;
  };

  const renderPlaylistGrid = () => {
    if (!playlist) return null;
    
    const isUnpublishedView = window.location.pathname.includes('/unpublished');
    const filteredHearticles = playlist.hearticles.filter(hearticle => {
      const translation = getTranslation(hearticle);
      const hasApprovedReview = translationReviews[translation.id]?.some(r => r.status === 'APPROVED');
      
      return isUnpublishedView ? !hasApprovedReview : hasApprovedReview;
    });
  
    return (
      <div className="mt-16">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-[#49ABDB] mb-2">{playlistName}</h1>
          <p className="text-[#5B5B5B]">{playlist.description}</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {filteredHearticles.map((article) => (
            <ArticleCard
              key={article.id}
              article={article}
              translation={getTranslation(article)}
              imageSize="md"
            />
          ))}
        </div>
      </div>
    );
  };

  const dailyReadCount = useSelector((state: RootState) => state.readCount.dailyReadCount);
  const user = useSelector((state: RootState) => state.auth.user);
  const isPaid = user?.roles?.includes('PERMISSION_PAID_ACCESS') || user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') || false;
  const hasReachedLimit = !isPaid && dailyReadCount >= 10;
   
  const renderSideHearticle = (article: Hearticle) => {
    const translation = getTranslation(article);
    return (
      <SideHearticleCard
        key={article.id}
        article={article}
        translation={translation}
        hasReachedLimit={hasReachedLimit}
        getImageUrl={getImageUrl}
      />
    );
  };

  const renderDailyHearticles = () => {
    if (dailyHearticles.length === 0 || !featuredArticle) return null;
    const featuredTranslation = getTranslation(featuredArticle);
    
    // Create the SEO-friendly URL for the featured article
    const featuredArticleUrl = `/${currentLanguage}/hearticle/${generateSlug(featuredTranslation.headline)}/${featuredArticle.id}`;

    return (
      <>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 xl:gap-[20px] mb-4 mt-16 xl:mt-48">
          {/* Featured Hearticle */}
          <div className="xl:col-span-2 bg-[#CAD4DD] p-4 xl:p-6 relative overflow-visible min-h-[400px] xl:h-[600px] flex flex-col">
            
            <div className="absolute left-1/2 transform -translate-x-1/2 -top-16 xl:-top-48">
              <img
                src={getImageUrl(featuredArticle.topic.name)}
                alt={featuredArticle.topic.name}
                className="w-32 h-32 xl:w-96 xl:h-96 object-contain"
              />
            </div>

            <div className="mt-16 xl:mt-48 flex justify-between flex-1">
              <div className="w-full xl:w-1/2">
                <div
                  className="px-2 py-1 rounded inline-block my-2 text-sm xl:text-base"
                  style={{
                    backgroundColor: featuredArticle.topic.color,
                    color: '#000000',
                  }}
                >
                  {topicName}
                </div>
                <h2 className="text-lg xl:text-2xl font-bold mb-2 text-[#111D4E]">
                  {t('heartport.todaysHearticle')}
                </h2>
                <h3 className="text-xl xl:text-3xl font-bold text-[#49ABDB] line-clamp-3">
                  {featuredTranslation?.headline}
                </h3>
                <p className="text-sm xl:text-lg text-[#111D4E] mt-2 line-clamp-2 italic">
                  {featuredTranslation?.keywords?.join(' - ')}
                </p>

                <div className="flex items-center gap-2">
                  <Link
                      to={featuredArticleUrl}
                      className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
                  >
                      {t('heartport.readFullHearticle')}
                  </Link>
                  <div className="mt-2">
                    <PlayButton
                      hearticleId={featuredArticle.id}
                      translationId={featuredTranslation.id}
                    />
                  </div>
                </div>

              </div>

              <div className="w-full md:w-1/3 flex flex-col items-center">
                <p className="mb-1 md:mb-2 text-sm md:text-lg text-white text-center">
                  Your Insights matter - read, share, democratize!
                </p>
                <p className="mb-1 md:mb-2 text-base md:text-2xl text-white font-bold text-center">
                  SHARE HEARTICLE
                </p>
                <div className="mt-1 md:mt-2 md:mb-14 mb-4">
                  <EnhancedShareButtons hearticleId={featuredArticle.id.toString()} />
                </div>
                <ReviewBadge 
                  review={translationReviews[featuredTranslation.id]?.find(r => r.status === 'APPROVED') ?? null} 
                  t={t} 
                />
              </div>
            </div>
          </div>

          {/* Side Hearticles */}
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-4 xl:gap-[20px]">
            {sideHearticles.map(article => renderSideHearticle(article))}
          </div>
        </div>

        {/* Grid Hearticles */}
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
          {gridHearticles.map((article) => (
            <ArticleCard
              key={article.id}
              article={article}
              translation={getTranslation(article)}
              imageSize="md"
            />
          ))}
        </div>
      </>
    );
  };

  if (loading) {
    return <div className="min-h-[400px] flex items-center justify-center">Loading...</div>;
  }

  return playlistId ? renderPlaylistGrid() : renderDailyHearticles();
};

export default HearticleDisplay;