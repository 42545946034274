import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { store } from './store';
import AppContent from './AppContent';
import { HelmetProvider } from 'react-helmet-async';

const App: React.FC = () => {

  console.log("env: ", process.env.REACT_APP_API_URL);

  return (
    <Provider store={store}>
      <HelmetProvider>
        <Router>
          <I18nextProvider i18n={i18n}>
            <AppContent />
          </I18nextProvider>
        </Router>
      </HelmetProvider>
    </Provider>
  );
};

export default App;