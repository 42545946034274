import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PlayButton from './PlayButton';
import { Hearticle } from '../types';
import AwayClickablePaywallModal from './AwayClickablePaywallModal';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface SideHearticleCardProps {
  article: Hearticle;
  translation: any; // Replace with proper type
  hasReachedLimit: boolean;
  getImageUrl: (topicName: string) => string;
}

const SideHearticleCard: React.FC<SideHearticleCardProps> = ({
  article,
  translation,
  hasReachedLimit,
  getImageUrl,
}) => {
  const { t, i18n } = useTranslation();
  const [showPaywall, setShowPaywall] = useState(false);
  const [topicName, setTopicName] = useState('');
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

  useEffect(() => {
    const currentLang = i18n.language;
    const name = currentLang === 'en' && article.topic.nameEn
      ? article.topic.nameEn
      : article.topic.name;
    setTopicName(name);
  }, [i18n.language, article.topic.name, article.topic.nameEn]);
  
  // Generate slug from headline
  const generateSlug = (text: string): string => {
    return text
      .toLowerCase()
      .replace(/[^\w\s-]/g, '') // Remove special characters
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
      .trim();
  };
  
  const handleClick = (e: React.MouseEvent) => {
    if (hasReachedLimit) {
      e.preventDefault();
      setShowPaywall(true);
    }
  };

  // Create the SEO-friendly URL
  const articleUrl = `/${currentLanguage}/hearticle/${generateSlug(translation.headline)}/${article.id}`;

  return (
    <>
      {showPaywall && <AwayClickablePaywallModal onClose={() => setShowPaywall(false)} />}
      <div className="bg-[#CAD4DD] p-4 relative min-h-[200px] xl:h-[290px] flex flex-col">
        <img
          src={getImageUrl(article.topic.name)}
          alt={article.topic.name}
          className="absolute top-2 right-2 w-16 h-16 xl:w-32 xl:h-32 object-contain"
        />
        <div className="pr-32 flex-1">
          <div
            className="px-2 py-1 rounded inline-block my-2 text-xs xl:text-base line-clamp-1"
            style={{
              backgroundColor: article.topic.color,
              color: '#000000',
            }}
          >
            {topicName}
          </div>
          <h3 className="text-base xl:text-xl font-bold text-[#49ABDB] line-clamp-2">
            {translation?.headline}
          </h3>
          <p className="text-xs xl:text-base text-black line-clamp-2 italic">
            {translation?.keywords?.join(' - ')}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <Link
            to={articleUrl}
            onClick={handleClick}
            className="inline-flex items-center justify-center h-12 px-4 py-2 mt-2 text-base xl:text-lg text-white font-bold bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
          >
            {t('heartport.readFullHearticle')}
          </Link>
          <div className="mt-2">
            <PlayButton
              hearticleId={article.id}
              translationId={translation.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SideHearticleCard;