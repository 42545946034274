import React, { useState, useEffect } from 'react';
import { AlertTriangle } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface User {
  id: number;
  email: string;
  username: string;
  roles: string[];
}

interface PaymentPlan {
  id: number;
  priceInCents: number;
  interval: string;
  description: string;
  subscriptionLevel: {
    name: string;
  };
}

interface APIErrorResponse {
  error: string;
  message: string;
}

interface SubscriptionDetails {
  status: 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'CANCELED' | 'PAST_DUE' | 'UNPAID';
  level: string;
}

const SubscriptionManagement: React.FC = () => {
  const { t } = useTranslation();
  const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [plans, setPlans] = useState<PaymentPlan[]>([]);
  const { user = null } = useSelector((state: RootState) => state.auth);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

  const premiumBenefits = {
    en: [
      "Unlimited Hearticles",
      "Unlimited Expert Guides",
      "Personalized Topic Suggestions",
      "Heartabase: Your Personal Hearticle Library",
      "Ad-free Experience"
    ],
    de: [
      "Unlimitierte Hearticles",
      "Unlimitierte Experten-Guides",
      "Personalisierte Themenvorschläge",
      "Heartabase: Ihre persönliche Hearticle-Bibliothek",
      "Keine Werbung"
    ]
  };

  // Fetch subscription status + all plans
  useEffect(() => {
    const loadSubscriptionData = async () => {
      if (!user?.id) return;
      try {
        const [statusRes, plansRes] = await Promise.all([
          axios.get(`/api/subscription-management/status/${user.id}`),
          axios.get('/api/subscription-management/payment-plans')
        ]);
        setSubscriptionDetails(statusRes.data);
        setPlans(plansRes.data);
      } catch (err) {
        setError('Failed to load subscription information');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };
    loadSubscriptionData();
  }, [user?.id]);

  const handlePlanSelection = async (planId: number) => {
    if (!user) {
      setError('User must be logged in to select a plan.');
      return;
    }
    try {
      const stripePubKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';
      const { data } = await axios.post('/api/subscription-management/create-checkout-session', null, {
        params: { userId: user.id, paymentPlanId: planId }
      });

      const stripe = await loadStripe(stripePubKey);
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }

      const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });
      if (error) throw error;
    } catch (err) {
      console.error('Error:', err);
      setError('Failed to process plan selection');
    }
  };

  const handleCancelSubscription = async () => {
    if (!user) {
      setError('User must be logged in to cancel the subscription.');
      return;
    }
    try {
      await axios.post('/api/subscription-management/cancel', null, {
        params: { userId: user.id }
      });
      const statusRes = await axios.get(`/api/subscription-management/status/${user.id}`);
      setSubscriptionDetails(statusRes.data);
    } catch (err) {
      setError('Failed to cancel subscription');
      console.error('Error:', err);
    }
  };

  const handleManageSubscription = async () => {
    if (!user) {
      setError('User must be logged in to manage the subscription.');
      return;
    }
    window.open('https://billing.stripe.com/p/login/8wMfZ22PY2vi2AM5kk', '_blank', 'noopener,noreferrer');
  };

  if (loading) {
    return <div className="p-4 text-center">{t('common.loading')}</div>;
  }

  if (error) {
    return (
      <div className="mx-4 sm:mx-0 bg-red-50 border border-red-200 rounded-lg p-4 flex items-center space-x-2">
        <AlertTriangle className="h-5 w-5 flex-shrink-0 text-red-500" />
        <p className="text-red-700 text-sm sm:text-base">{error}</p>
      </div>
    );
  }

  const isPaid = user?.roles?.includes('PERMISSION_PAID_ACCESS') || false;

  return (
    <div className="space-y-6 mx-4 sm:mx-0">
      {/* Current Subscription Status */}
      <div className="bg-white rounded-lg shadow p-4 sm:p-6">
        <h2 className="text-lg sm:text-xl font-bold text-gray-900 mb-4">
          {t('subscription.currentSubscription.title')}
        </h2>
        <div className="space-y-4">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-4 sm:space-y-0">
            <div>
              <h3 className="text-base sm:text-lg font-semibold text-gray-900">
                {subscriptionDetails?.level || 'Free'} {t('subscription.currentSubscription.plan')}
              </h3>
              <p className={`text-sm ${getStatusColor(subscriptionDetails?.status)}`}>
                {t('subscription.currentSubscription.status')}: {t(`subscription.status.${subscriptionDetails?.status?.toLowerCase() || 'inactive'}`)}
              </p>
            </div>
            {isPaid && (
              <button
                onClick={handleCancelSubscription}
                className="w-full sm:w-auto px-4 py-2 text-red-600 border border-red-600 rounded hover:bg-red-50 transition-colors text-sm sm:text-base"
              >
                {t('subscription.currentSubscription.cancel')}
              </button>
            )}
          </div>

          {isPaid && (
            <div className="mt-4">
              <button
                onClick={handleManageSubscription}
                className="w-full sm:w-auto px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors text-sm sm:text-base"
              >
                {t('subscription.currentSubscription.manage')}
              </button>
            </div>
          )}
        </div>
      </div>

      {!isPaid && (
        <>
          {/* Premium Benefits Section */}
          <div className="bg-white rounded-lg shadow p-4 sm:p-6">
            <h2 className="text-lg sm:text-xl font-bold text-gray-900 mb-4">
              {currentLanguage === 'de' ? 'Premium-Vorteile' : 'Premium Benefits'}
            </h2>
            <div className="bg-gray-50 rounded-lg p-4 sm:p-6">
              <ul className="space-y-3">
                {premiumBenefits[currentLanguage === 'de' ? 'de' : 'en'].map((benefit, index) => (
                  <li key={index} className="flex items-start">
                    <span className="mr-2 text-blue-600 flex-shrink-0">✓</span>
                    <span className="text-gray-700">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Available Plans Section */}
          <div className="bg-white rounded-lg shadow p-4 sm:p-6">
            <h2 className="text-lg sm:text-xl font-bold text-gray-900 mb-4">
              {t('subscription.availablePlans.title')}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {plans.map((plan) => (
                <div 
                  key={plan.id} 
                  className="border rounded-lg p-4 hover:shadow-lg transition-shadow flex flex-col justify-between"
                >
                  <div>
                    <h3 className="text-base sm:text-lg font-bold text-gray-900 mb-2">
                      {plan.description}
                    </h3>
                    <p className="text-sm text-gray-600 mb-4">
                    {new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR'
                    }).format(plan.priceInCents / 100)} {t(`subscription.availablePlans.${plan.interval.toLowerCase()}`)}                  </p>
                  </div>
                  <button
                    onClick={() => handlePlanSelection(plan.id)}
                    className="w-full px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors text-sm sm:text-base"
                  >
                    {t('subscription.availablePlans.selectPlan')}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const getStatusColor = (status: SubscriptionDetails['status'] | undefined): string => {
  switch (status) {
    case 'ACTIVE':
      return 'text-green-600';
    case 'PAST_DUE':
      return 'text-yellow-600';
    case 'CANCELED':
      return 'text-red-600';
    default:
      return 'text-gray-600';
  }
};

export default SubscriptionManagement;