import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import axios from '../axios';
import { useTranslation } from 'react-i18next';

interface ReadStats {
  topicId?: number;
  playlistId?: number;
  topicName?: string;
  playlistName?: string;
  readCount: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{
    payload: ReadStats;
  }>;
}

const colors = ['#49ABDB', '#A0C9DA', '#111D4E', '#6B7280', '#1E40AF', '#3B82F6', '#60A5FA', '#93C5FD'];

const ReadingStats: React.FC = () => {
  const { t } = useTranslation();
  const [topicData, setTopicData] = useState<ReadStats[]>([]);
  const [playlistData, setPlaylistData] = useState<ReadStats[]>([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [topicResponse, playlistResponse] = await Promise.all([
          axios.get<ReadStats[]>('/api/interactions/user/reads/by-topic'),
          axios.get<ReadStats[]>('/api/interactions/user/reads/by-playlist')
        ]);
        
        setTopicData(topicResponse.data);
        setPlaylistData(playlistResponse.data);
      } catch (error) {
        console.error('Error fetching read statistics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div></div>;
  }


  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const name = data.topicName || data.playlistName;
      return (
        <div className="bg-white p-2 rounded shadow">
          <p className="text-sm">{`${name}: ${data.readCount} ${t('profile.readArticles.reads')}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col gap-6 mb-8 text-black">
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-center text-[#111D4E] mb-4">
          {t('profile.readArticles.byTopic')}
        </h3>
        <div className="h-96">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={topicData}
                dataKey="readCount"
                nameKey="topicName"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={({ name }) => name && name.length > 15 ? `${name}` : name}
                labelLine={false}
              >
                {topicData.map((entry, index) => (
                  <Cell key={entry.topicId} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold text-center text-[#111D4E] mb-4">
          {t('profile.readArticles.byPlaylist')}
        </h3>
        <div className="h-80">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={playlistData}
                dataKey="readCount"
                nameKey="playlistName"
                cx="50%"
                cy="50%"
                outerRadius={80}
                label={({ name }) => name && name.length > 15 ? `${name}` : name}
                labelLine={false}
              >
                {playlistData.map((entry, index) => (
                  <Cell key={entry.playlistId} fill={colors[index % colors.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ReadingStats;