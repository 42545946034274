import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useTranslation } from 'react-i18next';

interface ReadCounterProps {
  className?: string;
  isFeatured: boolean;
  paywallCheckComplete: boolean;
}

const ReadCounter: React.FC<ReadCounterProps> = ({ 
  className = '', 
  isFeatured,
  paywallCheckComplete
}) => {
  const { t } = useTranslation();
  const dailyReadCount = useSelector((state: RootState) => state.readCount.dailyReadCount);
  const user = useSelector((state: RootState) => state.auth.user);
  const isPaid = user?.roles?.includes('PERMISSION_PAID_ACCESS') || 
                 user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') || 
                 false;

  // Only show counter if:
  // 1. Paywall check is complete
  // 2. User is not paid
  // 3. Article is not featured
  if (!paywallCheckComplete || isPaid || isFeatured) return null;

  return (
    <div className={`text-right mr-4 ${className}`}>
      <span className="text-[#49ABDB]">{t('readCounter.status', { count: dailyReadCount })}</span>
    </div>
  );
};

export default ReadCounter;