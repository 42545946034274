import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { getFeaturedTopicForDate } from '../utils/featuredTopicUtils';
import play from '../assets/images/play.png';
import pause from '../assets/images/pause.png';

interface PlayButtonProps {
  hearticleId: number;
  translationId: number;
}

const PlayButton: React.FC<PlayButtonProps> = ({ hearticleId, translationId }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isBlocked, setIsBlocked] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioUrl = `${process.env.REACT_APP_API_URL}/uploads/${hearticleId}/${translationId}.mp3`;
  
  const dailyReadCount = useSelector((state: RootState) => state.readCount.dailyReadCount);
  const dailyReadCountStatus = useSelector((state: RootState) => state.readCount.status);
  const user = useSelector((state: RootState) => state.auth.user);
  const isPaid = user?.roles?.includes('PERMISSION_PAID_ACCESS') || 
                 user?.roles?.includes('PERMISSION_REVIEW_HEARTICLES') || 
                 false;

  // Combined check for featured status and paywall
  useEffect(() => {
    let isMounted = true;

    const checkAccess = async () => {
      try {
        // Check featured status
        const featuredTopic = await getFeaturedTopicForDate(new Date());
        const response = await fetch(`/api/public/hearticle/${hearticleId}`);
        const article = await response.json();
        const isFeaturedArticle = article.topic.id === featuredTopic?.id;

        if (isMounted) {
          setIsFeatured(isFeaturedArticle);
          
          // Only block if all conditions are met
          const shouldBlock = !isPaid && 
                            dailyReadCount >= 10 && 
                            !isFeaturedArticle && 
                            dailyReadCountStatus === 'succeeded';
          
          setIsBlocked(shouldBlock);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error checking access:', error);
        if (isMounted) {
          setIsFeatured(false);
          setIsLoading(false);
        }
      }
    };
    
    checkAccess();

    return () => {
      isMounted = false;
    };
  }, [hearticleId, isPaid, dailyReadCount, dailyReadCountStatus]);

  const handleClick = () => {
    if (isBlocked) {
      alert("You've reached your daily free-read limit! Upgrade to continue.");
      return;
    }
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  // Show loading state only briefly during initial load
  if (isLoading && dailyReadCountStatus !== 'succeeded') {
    return (
      <div className="w-12 h-12 rounded-full bg-gray-300 animate-pulse"></div>
    );
  }

  return (
    <div>
      <button
        onClick={handleClick}
        disabled={isBlocked}
        className={`flex items-center justify-center w-12 h-12 rounded-full ${
          isBlocked ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90 transition-opacity'
        }`}
      >
        <img
          src={isPlaying ? pause : play}
          alt={isPlaying ? 'Pause' : 'Play'}
          className="w-full h-full"
        />
      </button>
      <audio
        ref={audioRef}
        src={audioUrl}
        onEnded={() => setIsPlaying(false)}
        className="hidden"
      />
    </div>
  );
};

export default PlayButton;