import { Download } from "lucide-react";

interface InstallButtonProps {
    onClick: () => void;
    isIOS: boolean;
    onShowGuide: () => void;
  }
  
  const InstallButton: React.FC<InstallButtonProps> = ({ onClick, isIOS, onShowGuide }) => {
    const handleClick = () => {
      if (isIOS) {
        onShowGuide();
      } else {
        onClick();
      }
    };
  
    return (
      <button
        onClick={handleClick}
        className="group flex items-center gap-2 rounded-xl bg-blue-600 hover:bg-blue-700 px-4 py-3 text-white shadow-lg transition-all duration-200"
      >
        <Download className="h-5 w-5" />
        <div className="flex flex-col items-start">
          <span className="text-xs font-medium opacity-90">
            {isIOS ? "GET" : "Install"}
          </span>
          <span className="text-[10px] opacity-75">
            {isIOS ? "Available on iOS" : "Available for Android"}
          </span>
        </div>
      </button>
    );
  };
  
  export default InstallButton;