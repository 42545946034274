import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import axios from '../axios';
import ArticleCard from './ArticleCard';
import { Hearticle } from '../types';
import { useTranslation } from 'react-i18next';
import ReadingStats from './ReadingStats';

const Heartabase = () => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState<Hearticle[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  
  const fetchArticles = async (pageNum: number) => {
    try {
      setLoading(true);
      const response = await axios.get('/api/interactions/user/read-hearticles', {
        params: {
          page: pageNum,
          size: 6
        }
      });

      setArticles(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching read articles:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles(page);
  }, [page]);

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="space-y-6">
    <ReadingStats />

      {!articles || articles.length === 0 ? (
        <p className="text-center py-8 text-[#49ABDB]">
          {t('profile.readArticles.noArticles', "You haven't read any articles yet.")}
        </p>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 gap-4">
            {articles.map((article) => (
                <div key={article.id} className="border border-white p-2">
                <ArticleCard
                    article={article}
                    translation={article.translations?.find(t => t.languageCode === currentLanguage) || article.translations?.[0]}
                    imageSize="md"
                />
                </div>
            ))}
          </div>


          <div className="flex justify-center items-center gap-4 mt-6 p-4">
            <button
              onClick={() => setPage(prev => Math.max(0, prev - 1))}
              disabled={page === 0}
              className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
            >
              {t('heartport.previous')}
            </button>
            <span className="text-[#111D4E]">
              {t('heartport.pageOf', { current: page + 1, total: totalPages })}
            </span>
            <button
              onClick={() => setPage(prev => Math.min(totalPages - 1, prev + 1))}
              disabled={page === totalPages - 1}
              className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
            >
              {t('heartport.next')}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Heartabase;