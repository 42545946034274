import React, { useEffect, useState } from 'react';
import axios from '../axios';
import { RootState } from '../store';
import { useSelector } from 'react-redux';

type ReviewerHearticleCountProjection = {
  reviewerId: number;
  reviewerUsername: string;
  reviewerEmail: string;
  hearticleCount: number;
};

type TopicReviewCountDto = {
  topicId: number;
  topicName: string;
  reviewCount: number;
};

type TopicUnreviewedHearticleCountDto = {
  topicId: number;
  topicName: string;
  unreviewedHearticleCount: number;
};

type ReviewerHearticlesProjection = {
  reviewerId: number;
  reviewerUsername: string;
  hearticleIds: number[];
};

type ExpandedReviewers = {
  [key: number]: boolean;
};

const ReviewStatistics: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  const [reviewerHearticleCounts, setReviewerHearticleCounts] = useState<ReviewerHearticleCountProjection[]>([]);
  const [topicReviewCounts, setTopicReviewCounts] = useState<TopicReviewCountDto[]>([]);
  const [unreviewedOverall, setUnreviewedOverall] = useState<number>(0);
  const [unreviewedByTopic, setUnreviewedByTopic] = useState<TopicUnreviewedHearticleCountDto[]>([]);

  const [reviewerHearticleData, setReviewerHearticleData] = useState<ReviewerHearticlesProjection[]>([]);
  const [expandedReviewers, setExpandedReviewers] = useState<ExpandedReviewers>({});

  const [formData, setFormData] = useState({ username: '', email: '' });
  const [formError, setFormError] = useState<string | null>(null);
  const [formSuccess, setFormSuccess] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  
  useEffect(() => {
    fetchDistinctHearticleCountByReviewer();
    fetchReviewCountByTopic();
    fetchUnreviewedOverall();
    fetchUnreviewedByTopic();
    fetchDistinctHearticlesDataByReviewer();
  }, []);

  const fetchDistinctHearticlesDataByReviewer = async () => {
    try {
      const response = await axios.get<ReviewerHearticlesProjection[]>('/api/review/distinct-hearticles-data-by-reviewer');
      setReviewerHearticleData(response.data);
    } catch (error) {
      console.error('Error fetching distinct hearticles data by reviewer:', error);
    }
  };

  const fetchDistinctHearticleCountByReviewer = async () => {
    try {
      const response = await axios.get<ReviewerHearticleCountProjection[]>('/api/review/distinct-hearticles-per-reviewer');
      setReviewerHearticleCounts(response.data);
      console.log("data: ", response.data)

    } catch (error) {
      console.error('Error fetching distinct hearticles per reviewer:', error);
    }
  };

  const fetchReviewCountByTopic = async () => {
    try {
      const response = await axios.get<TopicReviewCountDto[]>('/api/review/reviews-per-topic');
      setTopicReviewCounts(response.data);
    } catch (error) {
      console.error('Error fetching review count by topic:', error);
    }
  };

  const fetchUnreviewedOverall = async () => {
    try {
      const response = await axios.get<number>('/api/review/unreviewed-hearticles/overall');
      setUnreviewedOverall(response.data);
    } catch (error) {
      console.error('Error fetching unreviewed hearticles overall:', error);
    }
  };

  const fetchUnreviewedByTopic = async () => {
    try {
      const response = await axios.get<TopicUnreviewedHearticleCountDto[]>('/api/review/unreviewed-hearticles/by-topic');
      setUnreviewedByTopic(response.data);
    } catch (error) {
      console.error('Error fetching unreviewed hearticles by topic:', error);
    }
  };

  const getTotalReviewedHearticles = () => {
    return reviewerHearticleCounts.reduce((sum, reviewer) => sum + reviewer.hearticleCount, 0);
  };

  const toggleReviewerExpansion = (reviewerId: number) => {
    setExpandedReviewers(prev => ({
      ...prev,
      [reviewerId]: !prev[reviewerId]
    }));
  };

  // Add this navigation function
  const navigateToHearticle = (hearticleId: number) => {
    window.location.href = `/hearticle/${hearticleId}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormError(null);
    setFormSuccess(null);

    try {
      await axios.post('/api/admin/users', {
        username: formData.username,
        email: formData.email,
        roleNames: ['ROLE_REVIEWER']
      });

      setFormSuccess('Reviewer created successfully');
      setFormData({ username: '', email: '' });
      // Refresh the reviewer list
      fetchDistinctHearticleCountByReviewer();
    } catch (error: any) {
      setFormError(error.response?.data?.message || 'Failed to create reviewer');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Hearticle Review Statistics</h2>

      {user?.roles?.includes('PERMISSION_ADMIN') && (
        <div className="mb-8 p-4 bg-white rounded-lg shadow">
          <h3 className="text-xl font-semibold mb-4 text-black">Add New Reviewer</h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
              <input
                type="text"
                id="username"
                value={formData.username}
                onChange={(e) => setFormData(prev => ({ ...prev, username: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 text-black"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 text-black"
                required
              />
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed transition-colors"
            >
              {isSubmitting ? 'Creating...' : 'Add Reviewer'}
            </button>
          </form>
          
          {formError && (
            <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
              {formError}
            </div>
          )}
          
          {formSuccess && (
            <div className="mt-4 p-3 bg-green-100 border border-green-400 text-green-700 rounded">
              {formSuccess}
            </div>
          )}
        </div>
      )}

      {/* Summary Statistics */}
      <div className="grid grid-cols-2 gap-4 mb-8 p-4 bg-gray-50 rounded-lg">
        <div className="text-center p-4 bg-white rounded shadow">
          <h3 className="text-lg font-semibold text-gray-600 mb-2">Unreviewed Hearticles</h3>
          <p className="text-3xl font-bold text-blue-600">{unreviewedOverall}</p>
        </div>
        <div className="text-center p-4 bg-white rounded shadow">
          <h3 className="text-lg font-semibold text-gray-600 mb-2">Total Reviewed Hearticles</h3>
          <p className="text-3xl font-bold text-green-600">{getTotalReviewedHearticles()}</p>
        </div>
      </div>

      {/* Existing tables */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Distinct Hearticles per Reviewer</h3>
        <table className="min-w-full bg-white text-black border">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 text-left">Username</th>
              <th className="py-2 px-4 text-left">Email</th>
              <th className="py-2 px-4 text-left">Hearticle Count</th>
            </tr>
          </thead>
          <tbody>
            {reviewerHearticleCounts.map((item) => (
              <React.Fragment key={item.reviewerId}>
                <tr 
                  className="border-b cursor-pointer hover:bg-gray-50"
                  onClick={() => toggleReviewerExpansion(item.reviewerId)}
                >
                  <td className="py-2 px-4 flex items-center">
                    <span className="mr-2">
                      {expandedReviewers[item.reviewerId] ? '▼' : '▶'}
                    </span>
                    {item.reviewerUsername}
                  </td>
                  <td className="py-2 px-4">{item.reviewerEmail}</td>
                  <td className="py-2 px-4">{item.hearticleCount}</td>
                </tr>
                {expandedReviewers[item.reviewerId] && (
                  <tr>
                    <td colSpan={2} className="py-2 px-4 bg-gray-50">
                      <div className="pl-8">
                        <h4 className="font-semibold mb-2">Reviewed Hearticle IDs:</h4>
                        <div className="grid grid-cols-4 gap-2">
                          {reviewerHearticleData
                            .find(data => data.reviewerId === item.reviewerId)
                            ?.hearticleIds.map(hearticleId => (
                              <button
                                key={hearticleId}
                                onClick={() => navigateToHearticle(hearticleId)}
                                className="text-blue-600 hover:text-blue-800 hover:underline text-left p-1 rounded"
                              >
                                #{hearticleId}
                              </button>
                            ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>


      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Reviews per Topic</h3>
        <table className="min-w-full bg-white text-black border">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 text-left">Topic Name</th>
              <th className="py-2 px-4 text-left">Review Count</th>
            </tr>
          </thead>
          <tbody>
            {topicReviewCounts.map((item) => (
              <tr key={item.topicId} className="border-b">
                <td className="py-2 px-4">{item.topicName}</td>
                <td className="py-2 px-4">{item.reviewCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Unreviewed Hearticles by Topic</h3>
        <table className="min-w-full bg-white text-black border">
          <thead>
            <tr className="border-b">
              <th className="py-2 px-4 text-left">Topic Name</th>
              <th className="py-2 px-4 text-left">Unreviewed Count</th>
            </tr>
          </thead>
          <tbody>
            {unreviewedByTopic.map((item) => (
              <tr key={item.topicId} className="border-b">
                <td className="py-2 px-4">{item.topicName}</td>
                <td className="py-2 px-4">{item.unreviewedHearticleCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReviewStatistics;