import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLocalizedContent } from '../hooks/useLocalizedContent';
import logo from '../assets/images/logo512.png';
import bottom from '../assets/images/bottom.png';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import InstallButton from './InstallButton';

interface InstallationGuideProps {
  isIOS: boolean;
  onClose: () => void;
}

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { content: emailContent } = useLocalizedContent('footer.email');
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstallGuide, setShowInstallGuide] = useState<boolean>(false);
  const [isMobileWidth, setIsMobileWidth] = useState(window.matchMedia('(max-width: 767px)').matches);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAndroid = /Android/.test(navigator.userAgent);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileWidth(window.matchMedia('(max-width: 767px)').matches);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  const handleMailClick = () => {
    window.location.href = 'mailto:crew@heartport.org';
  };

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      setDeferredPrompt(null);
    }
  };

  const getPrivacyPolicyLink = (): string => {
    const lang = t('language') === 'de' ? 'de' : 'en';
    return `https://heartport.org/privacy_policy_${lang}.pdf`;
  };

  const getTandCLink = (): string => {
    return `https://heartport.org/agb.pdf`;
  };

  const content = {
    en: {
      title: isIOS ? 'Install on iOS' : 'Install on Android',
      steps: {
        ios: [
          'Tap the Share button in Safari',
          'Scroll down and tap "Add to Home Screen"',
          'Tap "Add" to confirm'
        ],
        android: [
          'Tap the menu icon (three dots) in Chrome',
          'Tap "Install app" or "Add to Home screen"',
          'Follow the on-screen instructions'
        ]
      },
      close: 'Close'
    },
    de: {
      title: isIOS ? 'Auf iOS installieren' : 'Auf Android installieren',
      steps: {
        ios: [
          'Tippen Sie auf die Teilen-Schaltfläche in Safari',
          'Scrollen Sie nach unten und tippen Sie auf "Zum Home-Bildschirm"',
          'Tippen Sie auf "Hinzufügen" zur Bestätigung'
        ],
        android: [
          'Tippen Sie auf das Menü-Symbol (drei Punkte) in Chrome',
          'Tippen Sie auf "App installieren" oder "Zum Startbildschirm hinzufügen"',
          'Folgen Sie den Anweisungen auf dem Bildschirm'
        ]
      },
      close: 'Schließen'
    }
  };

  const texts = content[currentLanguage === 'de' ? 'de' : 'en'];
  const steps = isIOS ? texts.steps.ios : texts.steps.android;

  const InstallationGuide: React.FC<InstallationGuideProps> = ({ isIOS, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white p-6 rounded-lg max-w-md">
        <h3 className="text-xl font-bold mb-4">
          {texts.title}
        </h3>
        <ol className="list-decimal pl-5 space-y-2">
          {steps.map((step, index) => (
            <li key={index} className="text-gray-700">{step}</li>
          ))}
        </ol>
        <button 
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
        >
          {texts.close}
        </button>
      </div>
    </div>
  );

  return (
    <footer className="relative flex flex-col items-center overflow-hidden text-center">
      <div className="mb-4 flex w-full flex-col">
        <div className="hidden md:flex w-full justify-between">
          <h2 className="m-0 w-1/2 pr-[10px] text-right text-[8rem] text-white">
            GET IN
          </h2>
        </div>
        <div className="hidden md:flex w-full justify-between">
          <h2 className="m-0 w-1/2 pl-[10px] text-left text-[8rem] text-white">
            <span
              className="text-transparent"
              style={{
                WebkitTextStroke: '1px white',
                WebkitTextFillColor: 'transparent',
              }}
            >
              TOUCH
            </span>
          </h2>
        </div>
        {/* Mobile version */}
        <div className="flex md:hidden flex-col items-center">
          <h2 className="m-0 text-[4rem] text-white">GET IN</h2>
          <h2 className="m-0 text-[4rem] text-white">
            <span
              className="text-transparent"
              style={{
                WebkitTextStroke: '1px white',
                WebkitTextFillColor: 'transparent',
              }}
            >
              TOUCH
            </span>
          </h2>
        </div>
      </div>
      <div
        className="mb-[40px] md:mb-[80px] ml-[-20%] h-[256px] w-[256px] md:h-[512px] md:w-[512px] bg-center bg-no-repeat bg-contain"
        style={{ backgroundImage: `url(${logo})` }}
      ></div>
      <a
        href="mailto:crew@heartport.org"
        className="mb-[25px] md:mb-[50px] text-[2rem] md:text-[3rem] text-[#48ABDB] no-underline"
      >
        {emailContent || 'crew@heartport.org'}
      </a>
      <div className="text-white text-sm md:text-base">{t('footer.contactRequest')}</div>
      <button
        onClick={handleMailClick}
        className="mt-[10px] md:mt-[20px] mb-3 md:mb-6 h-[80px] w-[80px] md:h-[120px] md:w-[120px] cursor-pointer rounded-full border-[2px] md:border-[3px] border-[#4a90e2] bg-transparent px-2 md:px-4 py-1 md:py-2 text-[1.2rem] md:text-[1.8rem] font-bold text-[#48ABDB]"
      >
        MAIL
      </button>
      {isMobileWidth && (deferredPrompt || isIOS || isAndroid) && (
        <InstallButton 
          onClick={handleInstallClick}
          isIOS={isIOS}
          onShowGuide={() => setShowInstallGuide(true)}
        />
      )}
      <div className="mt-2 md:mt-4 flex flex-col items-center gap-2 md:flex-row md:justify-center md:gap-4 mb-[155px] md:mb-[311px]">
        <Link
          to={getPrivacyPolicyLink()}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[0.8rem] md:text-[1rem] text-white no-underline hover:underline"
        >
          {t('footer.privacyPolicy')}
        </Link>
        <Link
          to={getTandCLink()}
          target="_blank"
          rel="noopener noreferrer"
          className="text-[0.8rem] md:text-[1rem] text-white no-underline hover:underline"
        >
          {t('footer.agb')}
        </Link>
        <a
          href="https://heartport.org/impressum"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[0.8rem] md:text-[1rem] text-white no-underline hover:underline"
        >
          {t('footer.impressum')}
        </a>
      </div>
      <div
        className="absolute bottom-0 left-0 h-[155px] md:h-[311px] w-full bg-cover bg-no-repeat"
        style={{ backgroundImage: `url(${bottom})` }}
      ></div>

      {showInstallGuide && (
        <InstallationGuide 
          isIOS={isIOS} 
          onClose={() => setShowInstallGuide(false)} 
        />
      )}
    </footer>
  );
};

export default Footer;