import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store';
import axios from '../axios';
import ArticleCard from './ArticleCard';
import { Hearticle, Topic } from '../types';
import { Search, ArrowRight } from 'lucide-react';
import debounce from 'lodash/debounce';

const HearticleExplorer = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState<Hearticle[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [articles, setArticles] = useState<Hearticle[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasPerformedSearch, setHasPerformedSearch] = useState(false);
  const [showAuthMessage, setShowAuthMessage] = useState(false);
  const [activeTopics, setActiveTopics] = useState<Topic[]>([]);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const user = useSelector((state: RootState) => state.auth.user);
  const [suppressSuggestions, setSuppressSuggestions] = useState(false);

  // Fetch active topics
  useEffect(() => {
    const fetchActiveTopics = async () => {
      try {
        const response = await axios.get('/api/topics/status/ACTIVE');
        setActiveTopics(response.data);
      } catch (error) {
        console.error('Error fetching active topics:', error);
        setActiveTopics([]);
      }
    };

    fetchActiveTopics();
  }, []);

  // Fetch initial recommendations
  useEffect(() => {
    const fetchRecommendations = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/hearticles/recommendations', {
          params: {
            personalizedCount: 0,
            generalCount: 8
          }
        });
        setArticles(response.data);
        setHasPerformedSearch(false);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
        setArticles([]);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  const getAuthMessage = () => {
    return currentLanguage === 'de' 
      ? "Bitte registriere Dich gratis um in der gesamten Heartport Bibliothek zu suchen"
      : "Please register for free to search the entire Heartport library";
  };

  const debouncedFetchSuggestions = debounce(async (term: string) => {
    if (!user) return;
    
    if (term.length >= 3) {
      try {
        const response = await axios.get('/api/public/hearticles/search/autocomplete', {
          params: { searchTerm: term, languageCode: currentLanguage }
        });
        setSuggestions(response.data);
        setShowSuggestions(true);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      }
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, 300);

  const performSearch = async (term: string, currentPage: number = 0) => {
    if (!user) return;
    
    setLoading(true);
    setHasPerformedSearch(true);
    try {
      const response = await axios.get('/api/public/hearticles/search', {
        params: {
          searchTerm: term,
          languageCode: currentLanguage,
          page: currentPage,
          size: 8
        }
      });
      setArticles(response.data.content);
      setTotalPages(response.data.totalPages);
      setShowSuggestions(false);
    } catch (error) {
      console.error('Error performing search:', error);
      setArticles([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTopicClick = (topic: any) => {
    if (!user) {
      setShowAuthMessage(true);
      return;
    }
    const topicName = topic.name;
    setSuppressSuggestions(true);
    setSearchTerm(topicName);
    performSearch(topicName);
    setPage(0);
    setShowSuggestions(false)
  };

  const getTopicName = (topic: Topic) => {
    return i18n.language === 'en' && topic.nameEn ? topic.nameEn : topic.name;
  };

  useEffect(() => {
    if (suggestions.length > 0) {
      setSuggestions([...suggestions]);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!user) return;
    if (suppressSuggestions) {
         setSuggestions([]);
         setShowSuggestions(false);
         return;
       }
    if (searchTerm) {
      debouncedFetchSuggestions(searchTerm);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [searchTerm]);

  const handleSearchFieldClick = () => {
    if (!user) {
      setShowAuthMessage(true);
      return;
    }
    setShowAuthMessage(false);
  };

  const handleSearch = (term: string = searchTerm) => {
    if (!user) return;
    
    if (term.length >= 3) {
      setSearchTerm(term);
      performSearch(term);
      setPage(0);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!user) return;
    
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSuggestionClick = (suggestion: Hearticle) => {
    if (!user) return;
    navigate(`/hearticle/${suggestion.id}`);
  };

  const handleChangeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!user) return;
    // As soon as they type, we turn suggestions back on
    setSuppressSuggestions(false);

    setSearchTerm(e.target.value);
  };

  return (
    <div className="w-full">
      {/* Search input */}
      <div className="relative max-w-5xl mx-auto mb-8">
      <input
          type="text"
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          onKeyDown={handleKeyDown}
          onClick={handleSearchFieldClick}
          placeholder={t('search.placeholder', 'Search hearticles...')}
          className="w-full p-4 pl-12 rounded-lg border-2 border-[#49ABDB] focus:outline-none focus:ring-2 focus:ring-[#49ABDB] focus:border-transparent text-lg text-black"
        />
        <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#49ABDB]" />
        

        {/* Auth message for non-registered users */}
        {showAuthMessage && !user && (
          <div className="absolute w-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50 text-center">
            <span className="text-gray-600">{getAuthMessage()}</span>
            <div className="mt-3">
              <button
                onClick={() => navigate('/signup')}
                className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] hover:opacity-90 transition-opacity"
              >
                {currentLanguage === 'de' ? 'Jetzt registrieren' : 'Register now'}
              </button>
            </div>
          </div>
        )}
        
        {/* Enhanced Suggestions dropdown - only shown for authenticated users */}
        {user && showSuggestions && suggestions.length > 0 && (
          <div className="absolute w-full bg-white rounded-lg shadow-lg border border-gray-200 max-h-72 lg:max-h-80 overflow-y-auto z-50 text-black">
            <div className="p-3 bg-gray-50 border-b border-gray-200 flex items-center justify-between">
              <span className="font-medium text-gray-600">{t('search.quickMatches', 'Quick Matches')}</span>
              <span className="text-sm text-gray-500">{suggestions.length} {t('search.found', 'found')}</span>
            </div>
            {suggestions.map((suggestion) => {
              const translation = suggestion.translations.find(t => t.languageCode === currentLanguage) || suggestion.translations[0];
              return (
                <button
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="w-full p-4 text-left hover:bg-gray-50 border-b border-gray-100 last:border-0"
                >
                  <div
                    className="px-2 py-1 rounded inline-block mb-2 text-sm"
                    style={{
                      backgroundColor: suggestion.topic.color,
                      color: '#000000',
                    }}
                  >
                    {getTopicName(suggestion.topic)}
                  </div>
                  <div className="font-bold text-[#49ABDB]">{translation.headline}</div>
                  <div className="text-sm text-gray-600 italic">
                    {translation.keywords?.join(' - ')}
                  </div>
                </button>
              );
            })}
            <div className="p-3 bg-gray-50 border-t border-gray-200 flex items-center justify-center">
              <span className="text-sm text-gray-600">
                {t('search.pressEnter', 'Press Enter for full search')} <ArrowRight className="inline-block w-4 h-4 ml-1" />
              </span>
            </div>
          </div>
        )}
        
        {/* Search hint when no suggestions - only shown for authenticated users */}
        {user && showSuggestions && suggestions.length === 0 && searchTerm.length >= 3 && !hasPerformedSearch && (
          <div className="absolute w-full mt-2 bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50 text-center">
            <span className="text-gray-600">
              {t('search.pressEnterToSearch', 'Press Enter to search for')} "<span className="font-medium">{searchTerm}</span>"
            </span>
          </div>
        )}
      </div>

      {/* Topic badges */}
      <div className="flex flex-wrap gap-2 my-4">
        {activeTopics.map((topic) => (
          <button
            key={topic.id}
            onClick={() => handleTopicClick(topic)}
            className="px-3 py-1 rounded-full text-sm font-medium transition-opacity hover:opacity-80"
            style={{
              backgroundColor: topic.color,
              color: '#000000',
            }}
          >
            {getTopicName(topic)}
          </button>
        ))}
      </div>

      {loading && (
        <div className="text-center py-8 text-[#49ABDB]">
          {t('loading', 'Loading...')}
        </div>
      )}

      {/* No results - only shown after search */}
      {!loading && articles.length === 0 && hasPerformedSearch && (
        <div className="text-center py-8 text-[#49ABDB]">
          {t('search.noResults', 'No articles found matching your search')}
        </div>
      )}

      {/* Articles grid - shows either search results or recommendations */}
      {!loading && articles.length > 0 && (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
            {articles.map((article) => (
              <ArticleCard
                key={article.id}
                article={article}
                translation={article.translations.find(t => t.languageCode === currentLanguage) || article.translations[0]}
                imageSize="md"
              />
            ))}
          </div>

          {/* Pagination - only shown for search results */}
          {hasPerformedSearch && (
            <div className="flex justify-center items-center gap-4 mt-6 p-4">
              <button
                onClick={() => {
                  setPage(prev => {
                    const newPage = Math.max(0, prev - 1);
                    performSearch(searchTerm, newPage);
                    return newPage;
                  });
                }}
                disabled={page === 0}
                className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
              >
                {t('heartport.previous')}
              </button>
              <span className="text-[#111D4E]">
                {t('heartport.pageOf', { current: page + 1, total: totalPages })}
              </span>
              <button
                onClick={() => {
                  setPage(prev => {
                    const newPage = Math.min(totalPages - 1, prev + 1);
                    performSearch(searchTerm, newPage);
                    return newPage;
                  });
                }}
                disabled={page === totalPages - 1}
                className="px-4 py-2 text-white font-bold rounded bg-gradient-to-r from-[#49ABDB] to-[#A0C9DA] disabled:opacity-50"
              >
                {t('heartport.next')}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HearticleExplorer;