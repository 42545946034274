import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store';

const PaywallModal = () => {
  const navigate = useNavigate();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const user = useSelector((state: RootState) => state.auth.user);
  
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const content = {
    en: {
      title: "Ah … Have you fallen in love with HEARTPORT?",
      description: "Your monthly quota has been reached. Become a premium member now and support Heartport in advancing prevention and making science accessible to everyone.",
      benefits: [
        "Unlimited Hearticles",
        "Unlimited Expert Guides",
        "Personalized Topic Suggestions",
        "Heartabase: Your Personal Hearticle Library",
        "Ad-free Experience"
      ],
      cta: user ? "Upgrade Now" : "Sign Up & Upgrade"
    },
    de: {
      title: "Ah … Have you fallen in love with HEARTPORT?",
      description: "Ihr Monatskontingent wurde aufgebraucht. Werden Sie jetzt Premium-Mitglied und unterstützen Sie Heartport dabei, Prävention voranzutreiben und Wissenschaft für alle zugänglich zu machen.",
      benefits: [
        "Unlimitierte Hearticles",
        "Unlimitierte Experten-Guides",
        "Personalisierte Themenvorschläge",
        "Heartabase: Ihre persönliche Hearticle-Bibliothek",
        "Keine Werbung"
      ],
      cta: user ? "Jetzt upgraden" : "Registrieren & upgraden"
    }
  };

  const texts = content[currentLanguage === 'de' ? 'de' : 'en'];

  const handleCtaClick = () => {
    // Reset scroll position before navigation
    window.scrollTo(0, 0);
    
    if (user) {
      navigate('/profile?tab=subscription');
    } else {
      navigate('/signup');
    }
  };

  return (
    <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4">
      <div className="bg-white w-full max-w-2xl rounded-lg max-h-[90vh] overflow-y-auto">
        <div className="p-4 md:p-8">
          <div className="space-y-4 md:space-y-6">
            <h2 className="text-xl md:text-3xl font-bold text-[#49ABDB] text-center mb-4 md:mb-6">
              {texts.title}
            </h2>
            
            <p className="text-base md:text-lg text-gray-700 text-center mb-6 md:mb-8 px-2 md:px-4">
              {texts.description}
            </p>
            
            <div className="bg-gray-50 p-4 md:p-6 rounded-lg">
              <h3 className="text-lg md:text-xl font-semibold text-[#49ABDB] mb-3 md:mb-4">
                {currentLanguage === 'de' ? 'Jetzt Premium-User werden und profitieren:' : 'Become a premium member and enjoy:'}
              </h3>
              <ul className="space-y-2 md:space-y-3">
                {texts.benefits.map((benefit, index) => (
                  <li key={index} className="flex items-start">
                    <span className="mr-2 text-[#49ABDB] text-base md:text-lg flex-shrink-0">✓</span>
                    <span className="text-gray-700 text-sm md:text-base">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mt-6 md:mt-8">
              <button
                onClick={handleCtaClick}
                className="w-full bg-[#49ABDB] text-white px-6 md:px-8 py-3 md:py-4 rounded-lg font-semibold text-base md:text-lg hover:bg-[#3d8fb8] transition-colors duration-200"
              >
                {texts.cta}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaywallModal;