import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import languageReducer from './languageSlice';
import cookieConsentReducer from './cookieConsentSlice';

// 1) import the new slice reducer
import readCountReducer from './readCountSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    language: languageReducer,
    cookieConsent: cookieConsentReducer,
    readCount: readCountReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
