import React, { useState, useEffect } from 'react';
import { Image, X } from 'lucide-react';
import { Hearticle } from '../types';

interface Playlist {
  id: number;
  name: string;
  nameEn?: string;
  description: string;
  creationDate: string;
  lastModifiedDate: string;
  status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
  hearticles: Hearticle[];
  creator?: {
    id: number;
    username: string;
  };
  editorIds?: number[];
}

interface PlaylistImageState {
  file: File | null;
  error: string | null;
}

interface PlaylistCardProps {
    playlist: Playlist;
    handleUpdateTopicColor: (playlistId: number, color: string) => Promise<void>;
    handleImageSelect: (playlistId: number) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleToggleStatus: (playlist: Playlist) => Promise<void>;
    playlistImages: Record<number, PlaylistImageState>;
    clearImageSelection: (playlistId: number) => void;
    handleImageUpload: (playlistId: number, playlistName: string) => Promise<void>;
  }
  
  const PlaylistCard: React.FC<PlaylistCardProps> = ({ 
    playlist, 
    handleUpdateTopicColor, 
    handleImageSelect, 
    handleToggleStatus,
    playlistImages,
    clearImageSelection,
    handleImageUpload 
  }) => {
    // Get the topic from the first hearticle (assuming all hearticles share the same topic)
    const topic = playlist.hearticles?.[0]?.topic;
    console.log("TOPIC: ", topic)
    const topicColor = topic?.color || '#E5E7EB'; // Default gray if no color
    
    // Now the hooks are at the top level of a component
    const [editingColor, setEditingColor] = useState<boolean>(false);
    const [colorValue, setColorValue] = useState<string>(topicColor);
    
    // Reset colorValue when topicColor changes (e.g., after an update)
    useEffect(() => {
      setColorValue(topicColor);
    }, [topicColor]);
    
    const imageState = playlistImages[playlist.id];
    
    // Construct the image URL for preview
    const imageUrl = `${process.env.REACT_APP_API_URL}/uploads/topic_images/${playlist.name.toLowerCase().replace(
      / /g,
      '_'
    )}.png`;
  
    return (
      <div
        className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden"
      >
        {/* Color strip at the top based on topic color */}
        <div 
          className="h-3" 
          style={{ backgroundColor: topicColor }}
        ></div>
        
        {/* Preview Image (hide if onError) */}
        <img
          src={imageUrl}
          alt={`Preview of ${playlist.name}`}
          className="w-full h-auto max-h-48 object-cover"
          onError={(e) => {
            (e.currentTarget as HTMLImageElement).style.display = 'none';
          }}
        />
  
        <div className="p-6">
          <div className="flex justify-between items-start mb-4">
            <h3 className="font-semibold text-lg text-gray-900 flex-1 pr-4">
              {playlist.name}
            </h3>
            <div className="flex gap-2 flex-shrink-0">
              {/* Buttons for playlist management */}
            </div>
          </div>
  
          <p className="text-sm text-gray-600 mb-4 line-clamp-2">
            {playlist.description}
          </p>
  
          {/* Color editing section */}
          <div className="mb-4">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-700">Color</span>
              {!editingColor ? (
                <button
                  onClick={() => setEditingColor(true)}
                  className="text-sm text-blue-600 hover:text-blue-800 transition-colors"
                >
                  Edit
                </button>
              ) : (
                <button
                  onClick={() => setEditingColor(false)}
                  className="text-sm text-gray-500 hover:text-gray-700 transition-colors"
                >
                  Cancel
                </button>
              )}
            </div>
            
            {editingColor ? (
              <div className="mt-2 flex items-center gap-2">
                <input
                  type="color"
                  value={colorValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setColorValue(e.target.value)}
                  className="h-8 w-10 p-0.5 border rounded"
                />
                <span className="text-sm font-mono text-gray-600">
                  {colorValue.toUpperCase()}
                </span>
                <button
                  onClick={() => {
                    handleUpdateTopicColor(playlist.id, colorValue);
                    setEditingColor(false);
                  }}
                  className="ml-auto text-sm bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition-colors"
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="mt-2 flex items-center gap-2">
                <div
                  className="h-5 w-5 rounded-full border border-gray-300"
                  style={{ backgroundColor: topicColor }}
                ></div>
                <span className="text-sm font-mono text-gray-600">
                  {topicColor.toUpperCase()}
                </span>
              </div>
            )}
          </div>
  
          {/* Image upload section */}
          <div className="mb-4">
            <label className="flex items-center gap-2 cursor-pointer p-2 border rounded-lg hover:bg-gray-50 transition-colors">
              <Image size={18} className="text-gray-600" />
              <span className="text-sm text-gray-700">Upload PNG Image</span>
              <input
                type="file"
                className="hidden"
                accept="image/png"
                onChange={handleImageSelect(playlist.id)}
              />
            </label>
  
            {imageState?.file && (
              <div className="mt-2 flex items-center gap-2 bg-gray-50 p-2 rounded-lg">
                <div className="flex-1 min-w-0">
                  <p className="text-sm text-gray-600 truncate">
                    {imageState.file.name}
                  </p>
                </div>
                <div className="flex gap-2 flex-shrink-0">
                  <button
                    onClick={() => handleImageUpload(playlist.id, playlist.name)}
                    className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                  >
                    Upload
                  </button>
                  <button
                    onClick={() => clearImageSelection(playlist.id)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X size={16} />
                  </button>
                </div>
              </div>
            )}
  
            {imageState?.error && (
              <p className="text-sm text-red-500 mt-1">{imageState.error}</p>
            )}
          </div>
  
          <div className="flex justify-between items-center">
            <button
              onClick={() => handleToggleStatus(playlist)}
              className={`px-3 py-1 rounded-full text-sm font-medium transition-colors ${
                playlist.status === 'ACTIVE'
                  ? 'bg-green-100 text-green-800 hover:bg-green-200'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {playlist.status}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default PlaylistCard;
  