import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { X } from 'lucide-react';

const GermanReviewAlert = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  
  useEffect(() => {
    if (currentLanguage === 'de') {
      setIsOpen(true);
    }
  }, [currentLanguage]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md mx-4 relative">
        <button 
          onClick={() => setIsOpen(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-5 w-5" />
        </button>
        
        <h2 className="text-xl font-semibold mb-4">
          Wichtiger Hinweis zur Überprüfung
        </h2>
        
        <p className="text-gray-700 mb-6">
          Aufgrund einer neuen Funktion werden englische Überprüfungen automatisch ins Deutsche übersetzt. 
          Bitte führen Sie Ihre Überprüfung auf Englisch durch. Dies gewährleistet eine effizientere 
          Verarbeitung und Konsistenz über alle Sprachversionen hinweg.
        </p>
        
        <div className="flex justify-end">
          <button
            onClick={() => setIsOpen(false)}
            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
          >
            Verstanden
          </button>
        </div>
      </div>
    </div>
  );
};

export default GermanReviewAlert;