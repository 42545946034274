import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import clouds from '../assets/images/clouds.png';

const PostSignup = () => {
  const navigate = useNavigate();
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const user = useSelector((state: RootState) => state.auth.user);

  // Calculate hue rotation based on day of year
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 0);
  const diff = today.getTime() - startOfYear.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const hue = dayOfYear % 360;

  const content = {
    en: {
      welcome: "Welcome to HEARTPORT!",
      subtitle: "Your account has been created successfully.",
      mainTitle: "Have you fallen in love with HEARTPORT?",
      description: "Become a premium member now and support Heartport in advancing prevention and making science accessible to everyone.",
      benefits: [
        "Unlimited Hearticles",
        "Unlimited Expert Guides",
        "Personalized Topic Suggestions",
        "Heartabase: Your Personal Hearticle Library",
        "Ad-free Experience"
      ],
      upgradeButton: user ? "Upgrade Now" : "Sign Up & Upgrade",
      continueButton: "Continue as Free"
    },
    de: {
      welcome: "Willkommen bei HEARTPORT!",
      subtitle: "Ihr Konto wurde erfolgreich erstellt.",
      mainTitle: "Haben Sie sich in HEARTPORT verliebt?",
      description: "Werden Sie jetzt Premium-Mitglied und unterstützen Sie Heartport dabei, Prävention voranzutreiben und Wissenschaft für alle zugänglich zu machen.",
      benefits: [
        "Unlimitierte Hearticles",
        "Unlimitierte Experten-Guides",
        "Personalisierte Themenvorschläge",
        "Heartabase: Ihre persönliche Hearticle-Bibliothek",
        "Keine Werbung"
      ],
      upgradeButton: user ? "Jetzt upgraden" : "Registrieren & upgraden",
      continueButton: "Kostenlos fortfahren"
    }
  };

  const texts = content[currentLanguage === 'de' ? 'de' : 'en'];

  const handleUpgradeClick = () => {
    window.scrollTo(0, 0);
    if (user) {
      navigate('/profile?tab=subscription');
    } else {
      navigate('/signup');
    }
  };

  const handleContinueFree = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen overflow-hidden relative">
      {/* Cloud background */}
      <div className="absolute inset-0 z-0">
        <div 
          className="absolute inset-0 bg-cover bg-no-repeat"
          style={{
            backgroundImage: `url(${clouds})`,
            filter: `hue-rotate(${hue}deg) saturate(70%) brightness(100%)`,
            opacity: 1.0
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-transparent" />
      </div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen p-6">
        <div className="w-full max-w-2xl bg-white/90 backdrop-blur-sm rounded-lg shadow-lg p-8">
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold text-[#49ABDB] mb-2">{texts.welcome}</h1>
            <p className="text-gray-600">{texts.subtitle}</p>
          </div>

          <div className="space-y-6">
            <h2 className="text-xl font-bold text-[#49ABDB] text-center">
              {texts.mainTitle}
            </h2>
            
            <p className="text-gray-700 text-center">
              {texts.description}
            </p>
            
            <div className="bg-white/80 backdrop-blur-sm p-6 rounded-lg">
              <h3 className="text-lg font-semibold text-[#49ABDB] mb-4">
                {currentLanguage === 'de' ? 'Premium-Vorteile:' : 'Premium Benefits:'}
              </h3>
              <ul className="space-y-3">
                {texts.benefits.map((benefit, index) => (
                  <li key={index} className="flex items-start">
                    <span className="mr-2 text-[#49ABDB] flex-shrink-0">✓</span>
                    <span className="text-gray-700">{benefit}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
              <button
                onClick={handleUpgradeClick}
                className="px-6 py-3 bg-[#49ABDB] text-white rounded-lg font-semibold hover:bg-[#3d8fb8] transition-colors"
              >
                {texts.upgradeButton}
              </button>
              <button
                onClick={handleContinueFree}
                className="px-6 py-3 bg-gray-200 text-gray-700 rounded-lg font-semibold hover:bg-gray-300 transition-colors"
              >
                {texts.continueButton}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostSignup;