import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store';
import {
  updateConsent,
  acceptAll
} from '../store/cookieConsentSlice';
import { useTranslation } from 'react-i18next';

interface CookieBannerProps {
  flowerImagePath: string;
}

const CookieBanner: React.FC<CookieBannerProps> = ({ flowerImagePath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { consent } = useSelector((state: RootState) => state.cookieConsent);
  const user = useSelector((state: RootState) => state.auth.user);
  const currentLanguage = useSelector((state: RootState) => state.language.currentLanguage);
  const [pendingAction, setPendingAction] = useState<'signup' | 'upgrade' | null>(null);
  const [showCookiePrompt, setShowCookiePrompt] = useState(false);

  if (consent.hasInteracted) {
    return null;
  }

  const handleNecessaryOnly = () => {
    dispatch(updateConsent({
      necessary: true,
      analytics: false,
      marketing: false,
      preferences: false
    }));
    if (pendingAction === 'signup') {
      navigate('/signup');
    } else if (pendingAction === 'upgrade') {
      navigate(user ? '/profile?tab=subscription' : '/signup');
    }
  };

  const handleAcceptAll = () => {
    dispatch(acceptAll());
    if (pendingAction === 'signup') {
      navigate('/signup');
    } else if (pendingAction === 'upgrade') {
      navigate(user ? '/profile?tab=subscription' : '/signup');
    }
  };

  const handleSubscriptionClick = (action: 'signup' | 'upgrade') => {
    setPendingAction(action);
    setShowCookiePrompt(true);
  };

  const subscriptionContent = {
    en: {
      startFree: "Start for free!",
      freeDesc: "Read up to 10 Hearticles per month for free.",
      freeFeatures: [
        "Hearticle of the Day",
        "Up to 10 Hearticles per month",
        "One Hearticle per Expert Guide",
        "Search the entire Heartport library"
      ],
      wantPersonalized: "Want personalized topic suggestions?",
      registerFree: "Register for free now",
      wantUnlimited: "Want Heartport unlimited?",
      goPremium: "Become Premium User! - 14.99 EUR/month",
      premiumFeatures: [
        "Unlimited Hearticles",
        "Unlimited Expert Guides",
        "Personalized Topic Suggestions",
        "Heartabase: Your Personal Hearticle Library",
        "Ad-free Experience"
      ],
      cookiePrompt: "Please accept our cookie policy to continue",
      acceptCookies: "Accept Cookies",
      acceptNecessaryCookies: "Accept Necessary Cookies Only"
    },
    de: {
      startFree: "Starte gratis!",
      freeDesc: "Jetzt bis zu 10 Hearticles pro Monat gratis lesen.",
      freeFeatures: [
        "Hearticle of the Day",
        "Bis zu 10 Hearticles pro Monat",
        "Je ein Hearticle pro Experten Guide",
        "Suche in der kompletten Bibliothek von Heartport"
      ],
      wantPersonalized: "Du möchtest personalisierte Themenvorschläge?",
      registerFree: "Jetzt gratis registieren",
      wantUnlimited: "Du möchtest Heartport unlimited?",
      goPremium: "Jetzt Premium User werden! - 14,99 EUR/Monat",
      premiumFeatures: [
        "Unlimtierte Hearticles",
        "Unlimitierte Experten Guides",
        "Personalisierte Themenvorschläge",
        "Heartabase: Ihre persönliche Hearticle Bibliothek",
        "Keine Werbung"
      ],
      cookiePrompt: "Bitte akzeptieren Sie unsere Cookie-Richtlinie, um fortzufahren",
      acceptCookies: "Cookies akzeptieren",
      acceptNecessaryCookies: "Nur notwendige Cookies akzeptieren"
    }
  };

  const texts = subscriptionContent[currentLanguage === 'de' ? 'de' : 'en'];

  return (
    <div className="fixed inset-0 bg-black/90 flex items-center justify-center z-50 p-4">
      <div className="bg-white w-full max-w-4xl relative rounded-lg max-h-[90vh] overflow-y-auto">
        <div className="relative z-20 p-4 md:p-8">
          {showCookiePrompt ? (
            <div className="space-y-4 md:space-y-6">
              <h2 className="text-xl md:text-2xl font-bold text-[#49ABDB] text-center">
                {texts.cookiePrompt}
              </h2>
              <div className="flex flex-col gap-3 mt-4 md:mt-6">
                <button
                  onClick={handleAcceptAll}
                  className="w-full px-3 py-2 md:px-4 md:py-2 bg-[#49ABDB] text-white rounded hover:bg-[#3d8fb8] transition-colors text-sm md:text-base"
                >
                  {texts.acceptCookies}
                </button>
                <button
                  onClick={handleNecessaryOnly}
                  className="w-full px-3 py-2 md:px-4 md:py-2 border border-[#49ABDB] text-[#49ABDB] rounded hover:bg-gray-50 transition-colors text-sm md:text-base"
                >
                  {texts.acceptNecessaryCookies}
                </button>
              </div>
            </div>
          ) : (
            <>
              {/* Cookie Consent Section */}
              <div className="mb-6 md:mb-8 pb-6 md:pb-8 border-b border-gray-200">
                <h2 className="text-lg md:text-xl font-medium mb-4 md:mb-5 text-gray-800">
                  {t('cookieConsent.title')}
                </h2>
                <p className="text-sm text-gray-600 mb-3 md:mb-4">
                  {t('cookieConsent.message')}
                </p>
                <p className="text-sm text-gray-600 mb-4 md:mb-6">
                  {t('cookieConsent.necessaryRequired')}
                </p>
                <div className="flex flex-col sm:flex-row justify-end gap-2">
                  <button
                    onClick={handleNecessaryOnly}
                    className="w-full sm:w-auto px-4 py-2 text-[#47ABDB] border border-[#47ABDB] hover:bg-[#f0f8ff] transition-colors text-sm md:text-base"
                  >
                    {t('cookieConsent.acceptNecessary')}
                  </button>
                  <button
                    onClick={handleAcceptAll}
                    className="w-full sm:w-auto px-4 py-2 bg-[#47ABDB] text-white border border-transparent hover:bg-[#0056b3] transition-colors text-sm md:text-base"
                  >
                    {t('cookieConsent.accept')}
                  </button>
                </div>
              </div>

              {/* Subscription Options Section */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                {/* Free Tier */}
                <div className="bg-gray-50 p-4 md:p-6 rounded-lg flex flex-col h-full">
                  <div>
                    <h3 className="text-xl md:text-2xl font-bold text-[#49ABDB] mb-3 md:mb-4">
                      {texts.startFree}
                    </h3>
                    <p className="text-gray-700 text-sm md:text-base mb-3 md:mb-4">
                      {texts.freeDesc}
                    </p>
                    <ul className="space-y-2 md:space-y-3 mb-4 md:mb-6">
                      {texts.freeFeatures.map((feature, index) => (
                        <li key={index} className="flex items-start text-sm md:text-base">
                          <span className="mr-2 text-[#49ABDB]">✓</span>
                          <span className="text-gray-700">{feature}</span>
                        </li>
                      ))}
                    </ul>
                    <p className="text-gray-700 font-medium text-sm md:text-base mb-3">
                      {texts.wantPersonalized}
                    </p>
                  </div>
                  <div className="mt-auto pt-3 md:pt-4">
                    <button
                      onClick={() => handleSubscriptionClick('signup')}
                      className="w-full px-3 py-2 md:px-4 md:py-2 bg-[#49ABDB] text-white rounded hover:bg-[#3d8fb8] transition-colors text-sm md:text-base"
                    >
                      {texts.registerFree}
                    </button>
                  </div>
                </div>

                {/* Premium Tier */}
                <div className="bg-[#49ABDB] p-4 md:p-6 rounded-lg text-white flex flex-col h-full">
                  <div>
                    <h3 className="text-xl md:text-2xl font-bold mb-3 md:mb-4">
                      {texts.wantUnlimited}
                    </h3>
                    <ul className="space-y-2 md:space-y-3">
                      {texts.premiumFeatures.map((feature, index) => (
                        <li key={index} className="flex items-start text-sm md:text-base">
                          <span className="mr-2">✓</span>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-auto pt-3 md:pt-4">
                    <button
                      onClick={() => handleSubscriptionClick('upgrade')}
                      className="w-full px-3 py-2 md:px-4 md:py-2 bg-white text-[#49ABDB] rounded font-bold hover:bg-gray-100 transition-colors text-sm md:text-base"
                    >
                      {texts.goPremium}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;